import React from 'react'
import { faMoneyBillAlt } from '@fortawesome/pro-duotone-svg-icons'
import DropDownItem from './drop-down-item'

const AwaitingPayment = () => {
  return (
    <DropDownItem
      url="/admin/payments/unpaid"
      text="Awaiting Payment"
      icon={faMoneyBillAlt}
    />
  )
}

export default AwaitingPayment
