import Fetch from 'Lib/fetch'

const CreateEntry = (entryParams) => {
  const { tournament_id, ...params } = entryParams
  return Fetch(`/tournaments/${tournament_id}/enter`,{
    method: 'POST',
    body: JSON.stringify({
      entry: params
    })
  })
}

export default CreateEntry
