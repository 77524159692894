import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBallotCheck } from '@fortawesome/pro-duotone-svg-icons'

const TournamentsAdmin = ({ tournamentIds }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(document.location.pathname === '/my-tournaments')
  }, [])

  return (
    <>
      {tournamentIds && tournamentIds.length > 0 && (
        <div className="nav-item d-flex align-items-center">
          <a
            className={`nav-link ${
              active && 'active'
            } d-flex align-items-center`}
            href="/my-tournaments"
          >
            <FontAwesomeIcon icon={faBallotCheck} className="me-1" />
            Manage ({tournamentIds.length})
          </a>
        </div>
      )}
    </>
  )
}

export default TournamentsAdmin
