import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Loading from 'Lib/loading'
import pluralize from 'pluralize'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEdit } from '@fortawesome/pro-duotone-svg-icons'

const TournamentAdmin = ({ url = 'my-tournaments' }) => {
  const { data: tournaments, error } = useSWR(url, Fetcher)
  return (
    <div>
      {!tournaments && !error && <Loading />}
      {error && <div className="alert alert-danger">An error occurred</div>}
      {tournaments && tournaments.length === 0 && (
        <div className="alert alert-info">
          You have no tournaments to administrate
        </div>
      )}
      {tournaments && tournaments.length > 0 && (
        <div className="list-group">
          {tournaments.map((tournament, key) => (
            <div className="d-flex flex-column list-group-item" key={key}>
              <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                <span className="fw-bold">{tournament.name}</span>
                <div
                  className="ms-sm-auto d-flex flex-column flex-sm-row align-items-sm-center"
                  style={{ fontSize: '0.9rem' }}
                >
                  <div className="d-flex align-items-center">
                    <span className="my-1 my-sm-0 me-0 me-sm-2">
                      {pluralize('entry', tournament.entries.length, true)}
                    </span>
                    {tournament.not_checked_in === 0 && tournament.entries > 0 && (
                      <div className="my-3 my-sm-0 text-success d-flex align-items-center me-2">
                        (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="me-1"
                        />
                        All teams checked in)
                      </div>
                    )}
                    {tournament.checked_in > 0 &&
                      tournament.not_checked_in > 0 && (
                        <div className="my-1 my-sm-0 text-success d-flex align-items-center me-2">
                          (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="me-1"
                          />
                          {pluralize('team', tournament.checked_in, true)}{' '}
                          checked in)
                        </div>
                      )}
                  </div>
                  <a
                    className={`ms-0 ms-sm-2 btn btn-sm btn-primary`}
                    href={`/tournaments/${tournament.id}/edit`}
                  >
                    <FontAwesomeIcon icon={faEdit} className="me-2" /> Manage
                  </a>
                </div>
              </div>
              {tournament.waiting_for.length > 0 && (
                <div
                  className="my-2 my-sm-0 text-muted d-flex align-items-center me-2"
                  style={{ fontSize: '0.8rem' }}
                >
                  Awaiting details for:{' '}
                  {tournament.waiting_for
                    .map((e) => e.team_name)
                    .sort()
                    .join(', ')}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default TournamentAdmin
