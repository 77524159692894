import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'

const Loading = ({message = 'loading', className = ''}) => {
  return (
    <div className={`d-flex align-items-center justify-content-center ${className}`}>
      <FontAwesomeIcon icon={faCircleNotch} spin className="me-2"/>
      {message}...
    </div> 
  )
}

export default Loading
