import React from 'react'
import TeamMember from './team-member'

const Team = ({entry, updateDraft}) => {
  return (
    <div className="list-group mb-3">
      <div className="list-group-item list-group-item-secondary d-flex align-items-center">
        Support team {entry.teamName}
      </div>
      <TeamMember
        currentName={entry.coach}
        currentPhone={entry.coachPhone}
        type="coach"
        key="coachEdit"
        updateDraft={updateDraft}
      />
      <TeamMember
        currentName={entry.referee}
        currentPhone={entry.refereePhone}
        type="referee"
        key="refereeEdit"
        updateDraft={updateDraft}
      />
      <TeamMember
        currentName={entry.firstAider}
        currentPhone={entry.firstAiderPhone}
        type="firstAider"
        key="firstAiderEdit"
        updateDraft={updateDraft}
      />
    </div>
  )
}

export default Team
