import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'
import Currency from 'Lib/currency'
import { mrfcMagenta } from 'Css/colors'
import styles from './index.module.scss'
import Confirm from 'Lib/confirm'
import Fetch from 'Lib/fetch'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import objectForRails from '../object-for-rails'

const Entry = ({
  entry,
  entryId,
  className = '',
  mutate,
  admin = false,
  bookerActions = true,
}) => {
  let { data: loadedEntry } = entry
    ? { data: entry }
    : useSWR(`/entries/${entryId}`, Fetcher)

  if (!loadedEntry) return null

  loadedEntry = loadedEntry && objectForRails(loadedEntry)

  const color = () => {
    if (loadedEntry.payment && loadedEntry.paid) {
      return 'secondary'
    } else {
      if (loadedEntry.payment && !loadedEntry.paid) {
        return 'warning'
      } else {
        return 'danger'
      }
    }
  }

  const removeFomBasket = ({
    entryType = 'basket',
    removalType = 'remove',
  }) => {
    Confirm({
      title: 'Are you sure',
      message: `Are you sure you want to ${removalType} ${loadedEntry.tournament} - ${loadedEntry.team_name} from your ${entryType}?`,
      onConfirm: () => {
        Fetch(`/entries/${loadedEntry.id}`, {
          method: 'DELETE',
        }).then((response) => {
          if (response.ok) {
            if (mutate) {
              mutate()
            }
          } else {
            alert(`An error occured - could not remove the ${removalType}`)
          }
        })
      },
    })
  }

  return (
    <div
      className={`d-flex flex-column flex-sm-row align-items-center justify-content-evenly justify-content-sm-end list-group-item list-group-item-${color()} ${className}`}
    >
      <div className="mb-3 mb-sm-0 me-0 me-sm-auto d-flex align-items-center">
        <span>
          {loadedEntry.tournament} - {loadedEntry.team_name}
        </span>
        {loadedEntry.paid &&
          loadedEntry.players &&
          loadedEntry.players_registered > 0 &&
          !loadedEntry.discarded_at && (
            <span
              className="ms-3 badge bg-success"
              style={{ fontSize: '0.9rem' }}
            >
              {loadedEntry.players_registered} players
            </span>
          )}
        {loadedEntry.paid &&
          loadedEntry.players_registered === 0 &&
          !loadedEntry.discarded_at && (
            <span className="ms-3 badge bg-info" style={{ fontSize: '0.9rem' }}>
              No players
            </span>
          )}
      </div>
      {!loadedEntry.discarded_at ? (
        <div
          className="mb-3 mb-sm-0 me-0 me-sm-2"
          style={{ fontSize: '0.9rem' }}
        >
          {loadedEntry.payment &&
            (loadedEntry.paid ? 'ENTERED & PAID' : 'ENTERED UNPAID')}
          {!loadedEntry.payment && 'NOT YET ENTERED'}
        </div>
      ) : (
        <div>REFUNDED</div>
      )}

      {!loadedEntry.paid && (
        <div className="mb-3 mb-sm-0 me-0 me-sm-2 badge bg-dark text-light">
          <Currency amount={loadedEntry.fee} />
        </div>
      )}
      {loadedEntry.payment && !loadedEntry.paid && (
        <>
          {loadedEntry.entered && (
            <a
              className={`btn btn-sm btn-outline-secondary mb-3 mb-sm-0 me-0 me-sm-2 ${styles.loadedEntryButton}`}
              onClick={() =>
                removeFomBasket({
                  removalType: 'withdraw',
                  entryType: 'confirmed entry',
                })
              }
            >
              Withdraw...
            </a>
          )}
          {bookerActions && (
            <>
              <a
                className={`btn btn-sm btn-secondary mb-3 mb-sm-0 me-0 me-sm-2 ${styles.loadedEntryButton}`}
                href={`/payments/${loadedEntry.payment.id}`}
              >
                {loadedEntry.entered
                  ? 'View bank details'
                  : 'Pay by bank transfer'}
              </a>
              <a
                className={`btn btn-sm text-light ${styles.loadedEntryButton}`}
                href={`/pay_by_card/${loadedEntry.payment.id}`}
                style={{
                  backgroundColor: mrfcMagenta,
                  borderColor: mrfcMagenta,
                }}
              >
                <FontAwesomeIcon icon={faCreditCard} className="me-2" />
                Pay by card
              </a>
            </>
          )}
        </>
      )}
      {!loadedEntry.entered && (
        <div
          className="ms-0 ms-sm-2 text-danger mb-3 mb-sm-0 me-0 me-sm-2"
          style={{ cursor: 'pointer' }}
          onClick={removeFomBasket}
        >
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
      )}
      {loadedEntry.entered && loadedEntry.paid && !loadedEntry.discarded_at && (
        <a
          className="btn btn-sm btn-primary"
          href={`${admin ? '/admin' : ''}/entries/${loadedEntry.id}`}
        >
          View..
        </a>
      )}
      {loadedEntry.discared_at && (
        <div className="btn btn-sm btn-danger disabled">Withdrawn</div>
      )}
    </div>
  )
}

export default Entry
