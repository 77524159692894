import React, { Fragment, useState } from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Loading from 'Lib/loading'

const AdminTournaments = () => {
  const { data: tournaments } = useSWR('/admin/tournaments', Fetcher)
  const [showAll,setShowAll] = useState(false)

  return (
    <div className="card mt-3">
      <div className="card-header d-flex align-items-center">
        Tournaments with entries
        <div
          className="ms-auto btn btn-sm btn-outline-secondary"
          onClick={() => setShowAll(!showAll)}
        >
          {showAll ? 'Show individual' : 'Show all entries'}
        </div>
      </div>
      <div className="card-body">
        {!tournaments && <Loading />}
        {tournaments && tournaments.length === 0 &&
          <div>
            There are no entries
          </div>
        }
        {tournaments && tournaments.length > 0 &&
          <>
            {showAll &&
              <div className="list-group list-group-flush">
                {tournaments.map((tournament, key) =>
                  <Fragment key={key}>
                    <div className="list-group-item list-group-item-primary">
                      {tournament.name}
                    </div>
                    <div className="list-group-item">
                      <div className="list-group">
                        {tournament.entries.map((entry, key) =>
                          <div className="list-group-item d-flex align-items-center">
                            <span>{entry.team_name}</span>
                            {entry.paid && <div className="ms-2 badge bg-dark">Paid</div>}
                            {!entry.paid && entry.entered && <div className="ms-2 badge bg-info">Waiting for payment</div>}
                            {!entry.paid && !entry.entered && <div className="ms-2 badge bg-warning">In Basket</div>}
                          </div>
                        )}
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            }
            {!showAll &&
              <div className="list-group">
                {tournaments.map((tournament, key) =>
                  <div className="list-group-item d-flex align-items-center" key={key}>
                    <div className="fw-bold me-2">{tournament.name}</div>
                    <div style={ { fontSize: '0.9rem' } }>
                      <span className="ms-2">{tournament.entries.filter(e => e.entered).length} confirmed entries</span> 
                      <span className="ms-2">{tournament.entries.filter(e => !e.entered).length} draft entries</span>
                    </div>
                    <div className="ms-auto">
                      <a
                        href={`/admin/tournaments/${tournament.id}`}
                        className="me-2 btn btn-sm btn-outline-secondary"
                      >
                        View
                      </a>
                      <a
                        href={`/tournaments/${tournament.id}/edit`}
                        className="btn btn-sm btn-outline-primary"
                      >
                        Edit..
                      </a>
                    </div>
                  </div>
                )}
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}

export default AdminTournaments
