import React from 'react'
import Currency from 'Lib/currency'
import { format, parseISO } from 'date-fns'
import { entries } from 'lodash'

const PaymentHeader = ({ payment }) => {
  return (
    <div className="d-flex align-items-center">
      <span>{format(parseISO(payment.created_at), 'P')}</span>
      <span className="ms-3">{payment.reference}</span>
      <span className="ms-3">
        {payment.paid
          ? payment.refunded
            ? 'REFUNDED'
            : 'PAID'
          : 'AWAITING PAYMENT'}
      </span>
      <span className="ms-3 badge bg-danger">
        {payment.entries.length}{' '}
        {payment.entries.length > 1 ? 'entries' : 'entry'}
      </span>
      <Currency amount={payment.amount} className="ms-auto" />
    </div>
  )
}

export default PaymentHeader
