import React from 'react'
import { formatPhoneNumber } from 'react-phone-number-input'

const PhoneLink = ({phone}) => {
  return (
    <a href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
  )
}

export default PhoneLink
