import React from 'react'
import Fixture from './fixture'

const FixtureList = ({fixtures, filter}) => {
  if (fixtures.length === 0) {
    return (
      <div className="alert alert-info my-3">
        There are no fixtures that match this filter
      </div>
    )
  }
  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Time</th>
            {!filter.pitch && <th>Pitch</th>}
            {filter.team && <th>Opposition</th>}
            {!filter.team &&
              <th colSpan="3">
                Match
              </th>
            }
          </tr>
        </thead>
        <tbody>
          {fixtures.map((fixture, key) =>
            <Fixture fixture={fixture} filterTeamId={filter.team} includePitch={!filter.pitch} key={key}/>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default FixtureList
