import isEqual from 'lodash.isequal'
import React, { useEffect, useState } from 'react'
import Fetch from 'Lib/fetch'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'

const Invoice = ({ paymentId, editable = true }) => {
  const baseUrl = `/payments/${paymentId}`
  const [address, setAddress] = useState()
  const [canSave, setCanSave] = useState(false)
  const { data: payment, mutate } = useSWR(baseUrl, Fetcher)

  useEffect(() => {
    if (payment) setCanSave(!isEqual(payment.invoice_address, address))
  }, [address])

  useEffect(() => {
    if (payment) setAddress(payment.invoice_address)
  }, [payment])

  const save = () => {
    Fetch(baseUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        payment: {
          invoice_address: address,
        },
      }),
    }).then((response) => {
      if (response.ok) {
        mutate()
      } else {
        response
          .json()
          .then((body) => alert(`An error occurred ${body.errors}`))
      }
    })
  }

  return (
    <div className="flex flex-col">
      <a href={`${baseUrl}.pdf`}>Download as pdf</a>
      {editable && (
        <div className="form-group">
          <label>
            Edit your invoice details and click save to update the invoice
          </label>
          <textarea
            className="form-control mb-3"
            value={address}
            rows={6}
            onChange={(e) => setAddress(e.target.value)}
          ></textarea>
          <div
            className={`btn btn-success btn-sm ${canSave ? '' : 'disabled'}`}
            onClick={save}
          >
            Save changes to invoice address
          </div>
        </div>
      )}
    </div>
  )
}

export default Invoice
