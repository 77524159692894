import React from 'react'
import Entry from 'Lib/entry'

const AdminTournament = ({tournament}) => {
  return (
    <div className="card mt-3">
      <div className="card-header">{tournament.name}</div>
      <div className="list-group">
        {tournament.entries.sort((a, b) => `${a.tournament}${a.team_name}` > `${b.tournament}${b.team_name}` ? 1 : -1).map((entry, key) =>
          <Entry entry={entry} key={key} admin={true} />
        )}
      </div>
    </div>
  )
}

export default AdminTournament
