import { id } from 'date-fns/locale'
import snakeCase from 'lodash.snakecase'

const filterIdForNewRecords = (param) => {
  if (!Array.isArray(param)) return param

  let newArray = []
  param.forEach((item) => {
    if (item._new) {
      const { id, _new, ...nestedParam } = item
      newArray.push(nestedParam)
    } else {
      newArray.push(item)
    }
  })

  return newArray
}

const objectForRails = (obj, validKeys = [], attributeKeys = []) => {
  const camelCaseObj = {}
  for (const key of Object.keys(obj)) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      (validKeys.length === 0 || validKeys.includes(key))
    ) {
      if (attributeKeys.includes(key)) {
        camelCaseObj[`${snakeCase(key)}_attributes`] = filterIdForNewRecords(
          obj[key]
        )
      } else {
        camelCaseObj[snakeCase(key)] = obj[key]
      }
    }
  }

  return camelCaseObj
}

export default objectForRails
