import React, { useState } from 'react'
import EmailValidator from 'email-validator'
import Fetch from 'Lib/fetch'

const NewAdmin = ({ url, entry, callback }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const valid = firstName && lastName && EmailValidator.validate(email)

  const addAdmin = () => {
    Fetch(`${url}/add_administrator`, {
      method: 'PATCH',
      body: JSON.stringify({
        email: email.toLowerCase(),
        first_name: firstName,
        last_name: lastName,
      }),
    }).then((response) => {
      if (response.ok) {
        callback()
      } else {
        response.json((data) => {
          alert(`An error occurred: ${data.errors}`)
        })
      }
    })
  }

  return (
    <div className="p-3">
      <div className="form-text fw-bold">Add a new team administrator</div>
      <div className="d-flex align-items-center mb-3">
        <div className="w-50 pe-3">
          <label className="form-label">First Name</label>
          <input
            type="text"
            className={`form-control ${
              firstName && firstName.length > 1 ? 'is-valid' : 'is-invalid'
            }`}
            defaultValue={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <div className="invalid-feedback">First name is required</div>
          <div className="valid-feedback">OK</div>
        </div>
        <div className="w-50 ps-3">
          <label className="form-label">Last Name</label>
          <input
            type="text"
            className={`form-control ${
              lastName && lastName.length > 1 ? 'is-valid' : 'is-invalid'
            }`}
            defaultValue={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <div className="invalid-feedback">Last name is required</div>
          <div className="valid-feedback">OK</div>
        </div>
      </div>
      <div className="mb-3">
        <label className="form-label">Email address</label>
        <input
          type="email"
          className={`form-control ${
            EmailValidator.validate(email) ? 'is-valid' : 'is-invalid'
          }`}
          defaultValue={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="invalid-feedback">Valid email is required</div>
        <div className="valid-feedback">OK</div>
      </div>
      <div className="mb-3 d-flex">
        <div className="btn btn-sm btn-outline-secondary" onClick={callback}>
          Cancel
        </div>
        {valid && (
          <div
            className={`ms-auto btn btn-sm btn-outline-success`}
            onClick={addAdmin}
          >
            Add {firstName} {lastName} as administrator..
          </div>
        )}
      </div>
    </div>
  )
}

export default NewAdmin
