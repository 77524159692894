import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons'

const DropDownItem = ({text, url, linkUrl, icon=faQuestionCircle}) => {
  const { data: items } = useSWR(url, Fetcher)

  return (
    <li>
      <a className="dropdown-item d-flex align-items-center" href={linkUrl || url}>
        <FontAwesomeIcon icon={icon} className="me-2" />{text}
        {items && items.length > 0 &&
          <div className="ms-2 badge bg-danger text-light">
            {items.length}
          </div>
        }
      </a>
    </li>
  )
}

export default DropDownItem
