import React, { Fragment } from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Loading from 'Lib/loading'
import orderBy from 'lodash.orderby'
import Confirm from 'Lib/confirm'
import Fetch from 'Lib/fetch'
import TournamentTeam from './team'

const CheckedInTeams = ({tournament, mutateTournament}) => {
  const tournamentId = tournament.id
  const { data: entries, mutate } = useSWR(`/tournaments/${tournamentId}/entries`, Fetcher)

  const checkedInTeams = entries && orderBy(entries.filter(e => e.checkedIn), ['teamName'])

  const clearCheckIn = (team) => {
    Confirm({
      title: 'Are you sure',
      message: `Are you sure you want to mark ${team.teamName} as not checked in?`,
      confirmText: `Clear Check in now`,
      onConfirm: () => {
        Fetch(`/tournaments/${tournamentId}/entries/${team.id}/clear-check-in`, {
          method: 'PATCH'
        }).then(response => {
          if (response.ok) {
            if (mutate) {
              mutate()
              mutateTournament()
            }
          } else {
            alert('An error occured - could not clear the checkin in the team')
          }
        })
      }
    })
  }

  if (!checkedInTeams) {
    return <Loading />
  } else {
    return (
      <div className="">
        <div className="list-group">
          {checkedInTeams.length > 0 && checkedInTeams.map((team,key) =>
          <Fragment key={key}>
            <div className="list-group-item list-group-item-success">
              Checked in
            </div>
            <TournamentTeam team={team} key={key} clearCheckIn={clearCheckIn} tournamentId={tournamentId}/>
          </Fragment>
          )}
          {checkedInTeams.length === 0 &&
            <div className="alert alert-danger my-3">No teams have checked in yet.</div>
          }
        </div>
      </div>
    )
  }
  
}

export default CheckedInTeams
