import React from 'react'
import {
  faCheckCircle,
  faExclamationTriangle,
  faFootballBall,
  faInfoCircle,
  faTimesCircle,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pluralize from 'pluralize'

const TournamentTeam = ({ team, checkIn, clearCheckIn, tournamentId }) => {
  return (
    <div
      id={`entry_${team.id}`}
      className="list-group-item d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-end"
    >
      <div className="me-sm-auto">
        <span className="fw-bold">{team.teamName}</span>
        <div
          className="mt-3 mt-sm-0 d-flex flex-column flex-sm-row align-items-sm-center"
          style={{ fontSize: '0.9rem' }}
        >
          {!team.fullTeamAvailable ? (
            <div>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="me-2 text-warning"
              />
            </div>
          ) : (
            <div className="me-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success me-1"
              />
              {team.playersRegistered} players registered,{' '}
              {team.fullTeamCheckedIn ? (
                <span>Full squad arrived ({team.playersCheckedIn})</span>
              ) : (
                <span>
                  {pluralize('player', team.playersCheckedIn, true)} marked as
                  arrived
                </span>
              )}
            </div>
          )}
          {team.coach && team.coachPhone && (
            <div className="me-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success me-1"
              />
              Coach
            </div>
          )}
          {team.referee && team.refereePhone && (
            <div className="me-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success me-1"
              />
              Referee
            </div>
          )}
          {team.firstAider && team.firstAiderPhone && (
            <div className="me-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="text-success me-1"
              />
              First Aider
            </div>
          )}
        </div>
      </div>
      <div className="mt-3 mt-sm-0 d-flex align-items-center justify-content-between">
        {!team.checkedIn && (
          <div
            className="btn btn-sm btn-outline-success mt-0 me-1 flex-grow-1 d-flex align-items-center justify-content-center"
            onClick={() => checkIn(team)}
          >
            <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
            Check in
          </div>
        )}
        <a
          href={`/tournaments/${tournamentId}/entries/${team.id}/fixtures`}
          className={`${
            team.matches === 0 && 'disabled'
          } btn btn-sm btn-outline-primary mt-0 mx-1 flex-grow-1 d-flex align-items-center justify-content-center`}
        >
          <FontAwesomeIcon icon={faFootballBall} className="me-2" />
          {team.matches === 0 ? 'No ' : ''}Fixtures{' '}
          {team.matches > 0 ? `(${team.matches})` : ''}
        </a>
        <a
          href={`/tournaments/${tournamentId}/entries/${team.id}`}
          className="btn btn-sm btn-outline-primary mt-0 ms-1 flex-grow-1 d-flex align-items-center justify-content-center"
        >
          <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
          <span className="d-sm-flex">Info</span>
        </a>
        {team.checkedIn && (
          <div
            className="btn btn-sm btn-outline-danger mt-0 ms-2 d-flex align-items-center justify-content-center"
            onClick={() => clearCheckIn(team)}
          >
            <FontAwesomeIcon icon={faTimesCircle} className="me-2" />
            Clear <span className="d-none d-sm-flex">Check in</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default TournamentTeam
