import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import Entry from 'Lib/entry'
import pluralize from 'pluralize'

const Entered = ({}) => {
  const { data: entries, mutate } = useSWR('/entries', Fetcher)

  let tournaments = {}
  tournaments =
    entries &&
    entries.reduce((t, entry) => {
      if (t[entry.tournament]) {
        t[entry.tournament].push(entry)
      } else {
        t[entry.tournament] = [entry]
      }
      return t
    }, {})

  const orderedTournaments =
    tournaments &&
    Object.keys(tournaments)
      .sort()
      .reduce((obj, key) => {
        obj[key] = tournaments[key]
        return obj
      }, {})

  return (
    <div className="py-3 d-flex flex-column">
      {!tournaments && (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faCircleNotch} spin className="me-2" />
          loading...
        </div>
      )}
      {tournaments && (
        <>
          {Object.entries(tournaments).length === 0 && (
            <div className="alert alert-info">
              You do not have any current teams entered into any of our{' '}
              <a href="/tournaments">tournaments.</a>
            </div>
          )}
          {Object.entries(tournaments).length > 0 && (
            <>
              <div className="px-3">
                Your teams and the tournaments they have entered are shown
                below.
              </div>
              <div className="list-group my-3">
                {Object.entries(orderedTournaments).map(
                  ([name, entries], key) => (
                    <div className="list-group-item" key={key}>
                      <div className="mb-3 fw-bold bg-dark rounded text-light p-2">
                        Maidenhead Festival 2022 : {name} -{' '}
                        {pluralize('squad', entries.length, true)}
                      </div>
                      {entries.map((entry, key) => (
                        <Entry entry={entry} key={key} mutate={mutate} />
                      ))}
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Entered
