import React from 'react'
import EntryShow from 'Lib/entry/show'

const TournamentEntryView = ({ entry }) => {
  const entryUrl = `/tournaments/${entry.tournament_id}/entries/${entry.id}`

  return <EntryShow entryUrl={entryUrl} isAdmin={true} />
}

export default TournamentEntryView
