import { format, parseISO } from 'date-fns'
import React from 'react'

const Fixture = ({ fixture, filterTeamId, includePitch = true }) => {
  const start = format(parseISO(fixture.startTime), 'kk:mm')

  return (
    <tr>
      <td>{start}</td>
      {includePitch && <td className="text-nowrap">{fixture.pitchName}</td>}
      {fixture.break ? (
        <td colSpan={filterTeamId ? 1 : 3} className="fw-bold">
          Break
        </td>
      ) : filterTeamId ? (
        <td>
          {parseInt(filterTeamId) === parseInt(fixture.homeTeam.id) &&
            fixture.awayTeam.teamName}
          {parseInt(filterTeamId) === parseInt(fixture.awayTeam.id) &&
            fixture.homeTeam.teamName}
        </td>
      ) : (
        <>
          <td className="text-nowrap">{fixture.homeTeam.teamName}</td>
          <td>vs</td>
          <td className="text-nowrap">{fixture.awayTeam.teamName}</td>
        </>
      )}
    </tr>
  )
}

export default Fixture
