// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start()
require('@rails/activestorage').start()
import 'channels'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'css/site'
import '@popperjs/core'
import '@fortawesome/fontawesome-pro/css/all.css'

require('trix')
require('@rails/actiontext')

import ahoy from 'ahoy.js'

const images = require.context('../images', true)

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
