import React from 'react'
import PhoneLink from 'Lib/phone-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faEdit,
  faExclamationTriangle,
} from '@fortawesome/pro-duotone-svg-icons'
import { orderBy } from 'lodash'
import Player from 'Lib/player'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'

const EntryShow = ({ entryUrl, isAdmin = false }) => {
  const { data: entry, mutate } = useSWR(entryUrl, Fetcher)
  return (
    <div className="card">
      <div className="card-header d-flex align-items-center">
        <span className="fw-bold">{entry?.team_name}</span>
        <a className="ms-auto btn btn-sm btn-primary" href={`${entryUrl}/edit`}>
          <FontAwesomeIcon icon={faEdit} className="me-2" />
          Edit..
        </a>
      </div>
      <div
        className="list-group list-group-flush"
        style={{ fontSize: '0.9rem' }}
      >
        <div className="list-group-item list-group-item-primary">
          Booked by {entry?.booked_by?.name} ({entry?.booked_by?.email} /{' '}
          <PhoneLink phone={entry?.booked_by?.phone} />)
        </div>
        <div
          className="list-group-item d-flex flex-column flex-sm-row align-items-center"
          style={{ fontSize: '0.9rem' }}
        >
          {entry?.administrators?.length === 0 && (
            <span>No additional administrators</span>
          )}
          {entry?.administrators?.length > 0 && (
            <div className="d-flex flex-column flex-sm-row align-items-center">
              <span className="fw-bold mb-2 mb-sm-0 me-0 me-sm-2">
                Additional administrators:
              </span>
              <div className="text-center">
                {new Intl.ListFormat().format(
                  entry?.administrators?.map((a) => a.email)
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="list-group list-group-flush">
        <div className="list-group-item list-group-item-primary">
          Coach, Referee & First Aider
        </div>
        {!entry?.coach && !entry?.referee && !entry?.first_aider && (
          <div className="list-group-item list-group-item-danger">
            None entered
          </div>
        )}
        {entry?.coach && (
          <div className="list-group-item d-flex flex-column flex-sm-row align-items-center">
            <span className="fw-bold">Coach:</span>
            <span className="mx-2">{entry?.coach}</span>
            <PhoneLink phone={entry?.coach_phone} />
          </div>
        )}
        {entry?.referee && (
          <div className="list-group-item d-flex flex-column flex-sm-row align-items-center">
            <span className="fw-bold">Referee:</span>
            <span className="mx-2">{entry?.referee}</span>
            <PhoneLink phone={entry?.referee_phone} />
          </div>
        )}
        {entry?.firstAider && (
          <div className="list-group-item d-flex flex-column flex-sm-row align-items-center">
            <span className="fw-bold">First Aider:</span>
            <span className="mx-2">{entry?.first_aider}</span>
            <PhoneLink phone={entry?.first_aider_phone} />
          </div>
        )}
      </div>
      <div className="list-group list-group-flush">
        <div className="list-group-item list-group-item-primary d-flex align-items-center">
          Players
          <div className="ms-auto">
            {entry?.full_team_checked_in ? (
              <div>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-success me-2"
                />
                Full team listed and arrived
              </div>
            ) : entry?.full_team_available ? (
              <>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-success me-2"
                />
                Full team listed
              </>
            ) : (
              <>
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="text-warning me-2"
                />
                Full team not registered
              </>
            )}
          </div>
        </div>
        {entry?.players?.length > 0 && (
          <>
            {orderBy(entry?.players, ['name']).map((player) => (
              <Player
                entry={entry}
                player={player}
                key={player.id}
                isAdmin={isAdmin}
                onChange={mutate}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default EntryShow
