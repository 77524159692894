import React, { useState } from 'react'
import { format, parseISO } from 'date-fns'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Fetch from 'Lib/fetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faTrashAlt,
  faUsersBetweenLines,
} from '@fortawesome/pro-duotone-svg-icons'
import Loading from 'Lib/loading'
import Confirm from 'Lib/confirm'
import EditTeam from './edit-team'
import orderBy from 'lodash.orderby'
import Select from 'react-select'

const Match = ({ baseUrl, match, admin = false, mutatePitches }) => {
  const [editingHome, setEditingHome] = useState(false)
  const [editingAway, setEditingAway] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [moving, setMoving] = useState(false)
  const [showSafe, setShowSafe] = useState(false)
  const { data: safeSwaps } = useSWR(
    admin && showSafe ? `${baseUrl}/matches/${match.id}/safe_swaps` : null,
    Fetcher
  )
  const { data: availablePitches, mutate } =
    useSWR(
      admin && showSafe ? `${baseUrl}/matches/${match.id}/safe_pitches` : null,
      Fetcher
    ) || []

  const { data: matches } = useSWR(
    admin ? `${baseUrl}/pitches/${match.pitchId}/matches` : null,
    Fetcher
  )

  const matchOptions =
    matches &&
    orderBy(
      matches.map((match) => {
        return {
          label: format(parseISO(match.startTime), 'kk:mm'),
          value: match.position,
          matchId: match.id,
        }
      }),
      ['label']
    )

  const movePitch = (newPitchId) => {
    setMoving(true)
    Fetch(`${baseUrl}/matches/${match.id}/move-to/${newPitchId}`, {
      method: 'PATCH',
    })
      .then((response) => {
        if (response.ok) {
          mutate()
          mutatePitches()
        } else {
          setMoving(false)
          response
            .json()
            .then((body) => alert(`An error occurred: ${body.errors}`))
        }
      })
      .catch((body) => {
        alert(`An error occurred`)
      })
  }

  const deleteMatch = () => {
    Confirm({
      title: 'Confirm removal of fixture',
      message:
        `Are you sure you want to remove the ${
          match.break ? `break` : `fixture`
        } ` +
        `${
          match.break
            ? ''
            : `${match.homeTeam.teamName} vs ${match.awayTeam.teamName}`
        } - this ` +
        'cannot be undone.',
      confirmText: 'Delete fixture',
      onConfirm: () => {
        Fetch(`${baseUrl}/matches/${match.id}`, {
          method: 'DELETE',
        }).then((response) => {
          if (response.ok) {
            mutate()
            mutatePitches()
          } else {
            response
              .json()
              .then((body) =>
                alert(`Could not remove the fixture: ${body.errors}`)
              )
          }
        })
      },
    })
  }

  const swapMatch = (swapWith) => {
    Confirm({
      title: 'Swapping matches',
      message:
        'Are you sure you want to swap the match ' +
        `${match.homeTeam.teamName} vs ${match.awayTeam.teamName} - with ` +
        ` the match ${swapWith.homeTeam.teamName} vs ${swapWith.awayTeam.teamName}?`,
      confirmText: 'Confirm Match Swap',
      onConfirm: () => {
        Fetch(`${baseUrl}/matches/${match.id}/swap/${swapWith.id}`, {
          method: 'POST',
        })
          .then((response) => {
            if (response.ok) {
              mutate()
            } else alert('Failed to swap fixtures')
          })
          .catch((b) => alert('An error occurred, could not complete swap'))
      },
    })
  }

  const updateTeam = (teamId, home = true) => {
    const columnName = home ? 'home_team_id' : 'away_team_id'
    setUpdating(true)
    Fetch(`${baseUrl}/matches/${match.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        match: {
          [columnName]: teamId,
        },
      }),
    }).then((response) => {
      setUpdating(false)
      if (response.ok) {
        mutate()
        mutatePitches()
      } else {
        response.json((body) =>
          alert(`Could not update fixture: ${body.errors}`)
        )
      }
    })
  }

  const changeSlot = (matchId, newSlot) => {
    setUpdating(true)
    Fetch(`${baseUrl}/pitches/${match.pitchId}/matches/${matchId}/move_to`, {
      method: 'PATCH',
      body: JSON.stringify({
        match: {
          new_position: newSlot.value,
        },
      }),
    }).then((response) => {
      if (response.ok) {
        mutatePitches()
        mutate()
        setUpdating(false)
      } else {
        setUpdating(false)

        response.json((body) => alert(`Could not move slot: ${body.errors}`))
      }
    })
  }

  return (
    <div
      className="list-group-item d-flex align-items-center"
      id={`match_${match.id}`}
    >
      {matchOptions && admin ? (
        <Select
          options={matchOptions}
          onChange={(newSlot) => changeSlot(match.id, newSlot)}
          value={matchOptions.find((o) => o.value === match.position)}
        />
      ) : (
        <span className="ms-2">
          {format(parseISO(match.startTime), 'kk:mm')}
        </span>
      )}
      <span className="mx-2">:</span>
      {!updating && !match.break && (
        <>
          {!editingHome && (
            <span className="d-flex align-items-center">
              {match.homeTeam.teamName}
              {admin && (
                <div
                  className="ms-2 badge bg-info"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setEditingHome(true)}
                >
                  Edit
                </div>
              )}
            </span>
          )}
          {editingHome && (
            <EditTeam
              baseUrl={baseUrl}
              home={true}
              match={match}
              cancel={() => setEditingHome(false)}
              update={updateTeam}
            />
          )}
          <span className="mx-2">vs</span>
          {!editingAway && (
            <span className="d-flex align-items-center">
              {match.awayTeam.teamName}
              {admin && (
                <div
                  className="ms-2 badge bg-info"
                  onClick={() => setEditingAway(true)}
                >
                  Edit
                </div>
              )}
            </span>
          )}
          {editingAway && (
            <EditTeam
              baseUrl={baseUrl}
              home={false}
              match={match}
              cancel={() => setEditingAway(false)}
              update={updateTeam}
            />
          )}
        </>
      )}
      {updating && <Loading message="updating match" />}
      {match.break && <div className="fw-bold">Break</div>}
      {match.clash && (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="ms-2 text-warning"
        />
      )}
      {admin && (
        <div className="ms-auto d-flex align-items-center">
          {showSafe ? (
            !match.break &&
            safeSwaps &&
            safeSwaps.length > 0 && (
              <div className="dropdown">
                <button
                  className="btn btn-outline-secondary dropdown-toggle me-3"
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  Swap with
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <h6 className="dropdown-header">
                      Matches that should be safe to swap
                    </h6>
                  </li>
                  {safeSwaps.map((swap, key) => (
                    <li key={key}>
                      <a
                        className="dropdown-item"
                        href="#"
                        key={key}
                        onClick={() => swapMatch(swap)}
                      >
                        {format(parseISO(swap.startTime), 'kk:mm')} :{' '}
                        {swap.homeTeam.teamName} vs {swap.awayTeam.teamName} (
                        {swap.pitchName})
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )
          ) : (
            <button
              onClick={() => setShowSafe(true)}
              className="rounded px-2 py-1 bg-white border"
            >
              Check safe
            </button>
          )}
          {!match.break &&
            showSafe &&
            !moving &&
            availablePitches &&
            availablePitches.length > 0 && (
              <select
                defaultValue="-1"
                className="form-select"
                onChange={(e) => {
                  movePitch(e.target.value)
                  e.target.value = -1
                }}
              >
                <option value="-1">Move to pitch...</option>
                {availablePitches.map((pitch) => (
                  <option
                    value={pitch.id}
                    key={`option_${pitch.id}_for_${match.id}}`}
                  >
                    {pitch.name}
                  </option>
                ))}
              </select>
            )}
          {moving && <Loading message="moving..." />}
          <div className="ms-2 text-danger btn">
            <FontAwesomeIcon icon={faTrashAlt} onClick={deleteMatch} />
          </div>
        </div>
      )}
    </div>
  )
}

export default Match
