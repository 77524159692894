import Fetch from 'Lib/fetch'

const CreateClub = (clubName) => {
  return Fetch('/clubs', {
    method: 'POST',
    body: JSON.stringify({
      club: {
        name: clubName
      }
    })
  })
}

export default CreateClub
