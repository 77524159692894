import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'

const BroadCastMessageContainer = () => {
  const { data: messages } = useSWR('/broadcast_messages', Fetcher)

  if (!messages || messages.length === 0) return null

  return (
    <div className="w-100 pt-3">
      {messages.map((message, key) => (
        <div
          className={`alert alert-${message.level} mb-0 alert-dismissible mb-3`}
          key={key}
        >
          <h6>{message.subject}</h6>
          <p>{message.message}</p>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            onClick={() => hide(message.id)}
          ></button>
        </div>
      ))}
    </div>
  )
}

export default BroadCastMessageContainer
