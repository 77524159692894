import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Loading from 'Lib/loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'

const AdminList = () => {
  const baseUrl = '/admin/broadcast_messages'
  const { data: messages } = useSWR(baseUrl, Fetcher)

  if (!messages) {
    return <Loading className="p-3" />
  } else {
    return (
      <div className="list-group">
        <div className="list-group-item list-group-item-secondary d-flex align-items-center">
          Broadcast messages
          <a className="ms-auto btn btn-sm btn-outline-secondary" href={`${baseUrl}/new`}>
            New message...
          </a>
        </div>
        {messages && messages.map((message, key) =>
          <div className="list-group-item d-flex align-items-center justify-content-end" key={key}>
            <div className="me-auto">
              {message.subject}
              <span className="ms-2" style={ { fontSize: '0.8rem' } }>
                {message.publishAt && <span className="">(from {message.publishAt} {message.publishUntil ? `to ${message.publishUntil}` : ''})</span>}
              </span>
            </div>
            {message.published && <span className="mx-2 badge bg-success">Published</span>}
            <a href={`${baseUrl}/${message.id}/edit`} className="btn btn-sm btn-primary">
              <FontAwesomeIcon icon={faEdit} className="me-2" />
              Edit...
            </a>
            <div
              className="ms-2 btn btn-sm btn-outline-danger"
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </div>
          </div> 
        )}
        {messages.lenbth === 0 &&
          <div classname="alert alert-info p-y">
            There are no messages in the system
          </div>
        }

      </div>
    )
  }
}

export default AdminList
