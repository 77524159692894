import { faClipboardCheck } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { confirmAlert } from 'react-confirm-alert'

const Confirm = ({
  title,
  message,
  onConfirm,
  icon = faClipboardCheck,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  confirmColour = 'primary',
}) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fw-bold d-flex align-items-center">
                  <FontAwesomeIcon icon={icon} className="me-2" />
                  {title}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                ></button>
              </div>
              <div className="modal-body">
                <p>{message}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={onClose}
                >
                  {cancelText}
                </button>
                <button
                  type="button"
                  className={`btn btn-${confirmColour}`}
                  onClick={() => {
                    onConfirm()
                    onClose()
                  }}
                >
                  {confirmText}
                </button>
              </div>
            </div>
          </div>
        </div>
      )
    },
  })
}

export default Confirm
