import React, { useState } from 'react'
import Fetch from 'Lib/fetch'
import { orderBy } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-duotone-svg-icons'
import Match from './match'
import NewSlot from './new-slot'

const Pitch = ({
  baseUrl,
  availableTeams,
  pitch,
  mutatePitches,
  admin = false,
}) => {
  const [tab, setTab] = useState('fixtures')

  const addTeam = (entryId) => {
    Fetch(`${baseUrl}/pitches/${pitch.id}/add_entry/${entryId}`, {
      method: 'PATCH',
    }).then((response) => {
      if (response.ok) {
        mutatePitches()
      } else {
        response
          .json()
          .then((body) => alert(`An error occurred: ${body.errors}`))
      }
    })
  }

  const removeEntry = (entryId) => {
    Fetch(`${baseUrl}/pitches/${pitch.id}/remove_entry/${entryId}`, {
      method: 'PATCH',
    }).then((response) => {
      if (response.ok) {
        mutatePitches()
      } else {
        response
          .json()
          .then((body) => alert(`An error occurred: ${body.errors}`))
      }
    })
  }

  return (
    <div className="card mb-3">
      <div className="card-header">Pitch: {pitch.name}</div>
      <div className="card-body p-0">
        {admin && (
          <ul className="nav nav-tabs border-bottom-0">
            <li className="nav-item">
              <a
                className={`nav-link ${tab === 'fixtures' && 'active'}`}
                href="#"
                onClick={() => setTab('fixtures')}
              >
                Fixtures
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${tab === 'teams' && 'active'}`}
                href="#"
                onClick={() => setTab('teams')}
              >
                Allocated Teams
                <div className="ms-2 badge bg-dark">{pitch.entries.length}</div>
              </a>
            </li>
          </ul>
        )}

        <div className="list-group">
          {tab === 'teams' && (
            <>
              {availableTeams.length > 0 && admin && (
                <div className="list-group-item">
                  <select
                    className="form-control"
                    onChange={(e) => {
                      addTeam(e.target.value)
                      e.target.value = null
                    }}
                  >
                    <option selected>
                      Select a team to add to {pitch.name}...
                    </option>
                    {availableTeams.map((entry, key) => (
                      <option value={entry.id} key={key}>
                        {entry.teamName}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {orderBy(pitch.entries, ['teamName']).map((entry, key) => (
                <div
                  className="list-group-item d-flex align-items-center"
                  key={key}
                >
                  <span className="fw-bold">{entry.teamName}</span>
                  <FontAwesomeIcon
                    className="text-danger ms-auto"
                    icon={faTrashAlt}
                    onClick={() => removeEntry(entry.id)}
                  />
                </div>
              ))}
            </>
          )}
          {tab === 'fixtures' && (
            <div className="d-flex flex-column">
              {pitch.matches.map((match) => (
                <Match
                  baseUrl={baseUrl}
                  match={match}
                  key={match.id}
                  admin={admin}
                  mutatePitches={mutatePitches}
                />
              ))}
              {pitch.matches.length === 0 && (
                <div className="alert alert-secondary mb-3">
                  There are no fixtures on this pitch
                </div>
              )}
              {admin && (
                <NewSlot
                  baseUrl={baseUrl}
                  pitch={pitch}
                  mutate={mutatePitches}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Pitch
