import React from 'react'

const AdminUser = ({user, suffix, allowDelete=false}) => {
  if (!user) return null

  return (
    <div className="list-group-item list-group-item-primary d-flex align-items-center">
      {user.name} ({user.email}) {suffix}
    </div>
  )
}

export default AdminUser
