import { sortBy } from 'lodash'
import React from 'react'
import Player from './player'

const Players = ({ entry, validYears, updateDraft }) => {
  const players = entry.players.filter((p) => !p._destroy)

  const update = (key, updates) => {
    const newPlayers = [...entry.players]
    const playerIndex = newPlayers.findIndex((p) => p.id === key)
    if (playerIndex !== -1) {
      newPlayers[playerIndex] = { ...newPlayers[playerIndex], ...updates }
    } else {
      newPlayers.push({ ...updates, _new: true, id: new Date().getTime() })
    }
    updateDraft({ players: newPlayers })
  }

  const additionalPlayers =
    players.length < entry.squad_size ? entry.squad_size - players.length : 0

  return (
    <div className="list-group">
      <div className="list-group-item list-group-item-secondary d-flex align-items-center">
        <span className="me-2">
          Registered Players (Squad size {entry.squad_size})
        </span>
        {additionalPlayers === 0 ? (
          <div className="ms-auto">
            You cannot add more players to this squad
          </div>
        ) : (
          <div className="ms-auto d-flex align-items-center">
            <span className="me-2 text-muted">
              {additionalPlayers} slot{additionalPlayers > 1 ? 's' : ''}{' '}
              available
            </span>
            <div
              className="btn bntn-sm btn-outline-secondary"
              onClick={() => update(new Date().getTime(), {})}
            >
              Add...
            </div>
          </div>
        )}
      </div>
      {sortBy(players, ['created_at']).map((player, key) => (
        <Player
          player={player}
          id={player.id}
          key={player.id}
          years={validYears}
          update={(params) => update(player.id, params)}
        />
      ))}
      {players.length == 0 && (
        <div className="list-group-item">
          There are no players registered in the squad yet. Use the button above
          to add players.
        </div>
      )}
    </div>
  )
}

export default Players
