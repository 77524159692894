import React, { useEffect, useState } from 'react'
import humanize from 'underscore.string/humanize'
import Phone from 'Lib/phone'

const TeamMember = ({currentName, currentPhone, type, updateDraft}) => {
  const [phone,setPhone] = useState(currentPhone)
  const [name,setName] = useState(currentName)
  const [phoneValid,setPhoneValid] = useState(false)

  useEffect(() => {
    if (phoneValid) {
      updateDraft({ [`${type}Phone`]: phone })
    }
  },[phoneValid])

  useEffect(() => {
    if (name) {
      updateDraft({ [type]: name })
    }
  }, [name])

  return (
    <div className="list-group-item d-flex flex-column">
      <label className="form-label">{humanize(type)}</label>
      <div className="d-flex flex-column flex-sm-row align-items-sm-center">
        <input
          className="form-control mb-3 mb-sm-0 me-0 me-sm-3"
          type="text"
          defaultValue={name}
          placeholder={`${humanize(type)} name`}
          onChange={e => setName(e.target.value)}
        />
        <Phone
          phone={phone}
          update={setPhone}
          setValid={setPhoneValid}
          placeholder={`${humanize(type)} mobile number`}
        />
      </div>
    </div>
  )
}

export default TeamMember
