import {
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pluralize from 'pluralize'
import React from 'react'

const FixtureCheck = ({ tournament }) => {
  let allOK = true
  if (
    tournament.match_summary.some(
      (team) => team.total_matches < (tournament?.target_fixtures || 5)
    )
  ) {
    allOK = false
  }

  return (
    <div
      className="my-3 list-group"
      style={{ fontSize: '0.8rem', cursor: 'pointer' }}
    >
      <div
        className="list-group-item list-group-item-info"
        data-bs-toggle="collapse"
        data-bs-target="#teamInfo"
      >
        Fixture number check
        {allOK && (
          <FontAwesomeIcon icon={faCheckCircle} className="ms-2 text-success" />
        )}
        {!allOK && (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="ms-2 text-danger"
          />
        )}
      </div>
      <div id="teamInfo" className="collapse">
        {tournament.match_summary.map((team, key) => (
          <div className="list-group-item" key={key}>
            {team.name} : {pluralize('match', team.total_matches, true)}
            {team.total_matches >= (tournament?.target_fixtures || 5) ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="ms-2 text-success"
              />
            ) : (
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="ms-2 text-danger"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default FixtureCheck
