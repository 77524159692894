import React, { useEffect, useState } from 'react'
import Phone from 'Lib/phone'
import Fetch from 'Lib/fetch'
import isEqual from 'lodash.isequal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons'
import objectForRails from 'Lib/object-for-rails'
import Loading from 'Lib/loading'

const PhotoRegistration = ({photoRegistration}) => {
  const [draft,setDraft] = useState(photoRegistration)
  const [phoneValid, setPhoneValid] = useState(false)
  const [saving,setSaving] = useState(false)

  const isDirty = !isEqual(draft, photoRegistration)

  const canSave = draft && draft.user && draft.photographyType && draft.purpose &&
                  draft.user.firstName && draft.user.phone && phoneValid &&
                  draft.user.lastName && draft.user.address1 && draft.user.postCode && 
                  draft.user.city

  const updateUser = (userUpdates) => {
    const user = { ...draft.user, ...userUpdates }
    setDraft(d => ({...d, user: user }))
  }

  const updateDraft = (updates) => {
    setDraft(d => ({...d, ...updates}))
  }

  const save = () => {
    const updates = draft
    // change to attributes
    updates.user_attributes = objectForRails(draft.user)
    delete(updates.user)
    setSaving(true)
    Fetch(`/photo_registrations/${draft.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        photo_registration: objectForRails(updates)
      })
    }).then(response => {
      setSaving(false)
      if (response.ok) {
        response.json().then(body => setDraft(body))
      } else {
        response.json().then(body => alert(`An error occurred: ${body.errors}`))
      }
    })
  }

  return (
    <div className="p-0 p-sm-3">
      {!saving && draft && draft.user &&
        <>
          {draft.agreedAt &&
            <div className="alert alert-success my-3">
              <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
              Submitted and agreed on {draft.agreedAt}
            </div>
          }
          <div className="card mb-3">
            <div className="card-header">Your details</div>
            <div className="card-body">
              <div className="form-floating mb-3">
                <input type="text" className="form-control" defaultValue={draft.user.email} disabled/>
                <label >Email</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control`}
                  defaultValue={draft.user.firstName}
                  onChange={(e) => updateUser({firstName: e.target.value})} 
                />
                <label >First Name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control`}
                  defaultValue={draft.user.lastName} 
                  onChange={(e) => updateUser({lastName: e.target.value})}
                />
                <label >Last Name</label>
              </div>
              <div className="form-floating">
                  <Phone
                    phone={draft.user.phone}
                    update={(newPhone) => updateUser({phone: newPhone})}
                    setValid={setPhoneValid}
                  />
                <label >Mobile</label>
              </div>
              <label className="form-label mt-3">
                Address
              </label>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control mb-3 ${draft.user.address1 ? 'is-valid' : 'is-invalid'}`}
                  defaultValue={draft.user.address1}
                  placeholder="Address line 1"
                  onChange={(e) => updateUser({address1: e.target.value})}
                />
                <label>Address line 1</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control mb-3 ${draft.user.address2 && 'is-valid'}`}
                  defaultValue={draft.user.address2}
                  placeholder="Address line 2 (optional"
                  onChange={(e) => updateUser({address2: e.target.value})}
                />
                <label>Address line 2 (optional)</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control mb-3 ${draft.user.city ? 'is-valid' : 'is-invalid'}`}
                  defaultValue={draft.user.city}
                  placeholder="Town Cirty"
                  onChange={(e) => updateUser({city: e.target.value})}
                />
                <label>Town / City</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className={`form-control mb-3 ${draft.user.postCode ? 'is-valid' : 'is-invalid'}`}
                  defaultValue={draft.user.postCode}
                  placeholder="Postcode"
                  onChange={(e) => updateUser({postCode: e.target.value})}
                />
                <label>Postcode</label>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-header">About your photography at the event</div>
            <div className="card-body">
              <div className="mb-3 d-flex flex-column flex-sm-row">
                <label className="form-label me-3 fw-bold">Your role</label>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="photographyType"
                    onClick={() => updateDraft({photographyType: 'media'})}
                    defaultChecked={draft.photographyType === 'media'}
                  />
                  <label className="form-check-label">Media</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="photographyType"
                    onClick={() => updateDraft({photographyType: 'parental'})}
                    defaultChecked={draft.photographyType === 'parental'}
                  />
                  <label className="form-check-label">parent</label>
                </div>
                <div className="form-check form-check-inline">
                  <input className="form-check-input" type="radio" name="photographyType"
                         onClick={() => updateDraft({photographyType: 'guardian'})}
                         defaultChecked={draft.photographyType === 'guardian'}
                  />
                  <label className="form-check-label">Guardian</label>
                </div>
              </div>
              <label className="form-label">Purpose</label>
              <textarea
                placeholder="Describe the purpose of your photography"
                className="form-control"
                rows="3"
                onChange={(e) => updateDraft({purpose: e.target.value})}
                defaultValue={draft.purpose}
              />
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              Declaration
            </div>
            <div className="card-body">
              <p>I wish to take photographs at the event. 
              I agree to abide by the event organisers' guidelines that the photographs or recorded images
              will only be used approriately.</p>
              <div className="mt-3 d-flex flex-column align-items-center">
              <div
                className={`btn btn-success ${(canSave && isDirty) ? '' : 'disabled'}`}
                onClick={save}
              >
                Click to submit details and confirm your agreement
              </div>
            </div>
            </div>
          
          </div>
        </>
      }
      {saving &&
        <div className="my-2">
          <Loading message="saving consent..." />
        </div>
      } 
    </div>
  )
}

export default PhotoRegistration
