import React from 'react'
import Expander from 'Lib/expander'
import Currency from 'Lib/currency'
import PaymentHeader from './payment-header'
import Entry from 'Lib/entry'
import Invoice from '../invoice'

const Payment = ({ payment }) => {
  return (
    <Expander title={<PaymentHeader payment={payment} />}>
      <div className="f-flex flex-col">
        <div className="fw-bold">Entries</div>
        {payment.entries.map((entry) => (
          <Entry entryId={entry.id} key={entry.id} />
        ))}
        <div className="fw-bold mt-3">Downloadable Invoice</div>
        <Invoice paymentId={payment.id} editable={true} />
      </div>
    </Expander>
  )
}

export default Payment
