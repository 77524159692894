import { faUserTimes } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

const Player = ({ player, years, update }) => {
  const [name, setName] = useState(player ? player.name : null)
  const [year, setYear] = useState(
    player && player.date_of_birth ? player.date_of_birth.slice(0, 4) : null
  )
  const [month, setMonth] = useState(
    player && player.date_of_birth
      ? parseInt(player.date_of_birth.slice(5, 7))
      : null
  )
  const [day, setDay] = useState(
    player && player.date_of_birth
      ? parseInt(player.date_of_birth.slice(8, 10))
      : null
  )

  useEffect(() => {
    if (year && month && day) {
      update({
        date_of_birth: `${year.toString().padStart(4, '0')}-${month
          .toString()
          .padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
      })
    }
  }, [year, month, day])

  useEffect(() => {
    if (name) {
      update({ name: name })
    }
  }, [name])

  const clearPlayer = () => {
    setName(null)
    setYear(null)
    setMonth(null)
    setDay(null)
    update({ date_of_birth: null, name: null, _destroy: true })
  }

  return (
    <div className="list-group-item d-flex flex-column flex-sm-row align-items-sm-end">
      <div className="pe-0 pe-sm-3 flex-grow-1">
        <label className="form-label">Name</label>
        <input
          type="text"
          className={`form-control w-100`}
          value={name || ''}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="ps-0 ps-sm-3 mt-3 mt-sm-0">
        {false && (
          <>
            <label className="form-label">Date of birth</label>
            <div className="d-flex align-items-center">
              <select
                id="year"
                className="form-select me-3"
                onChange={(e) => setYear(e.target.value)}
                value={year || '-1'}
              >
                <option value="-1">Year</option>
                {years.map((y, key) => (
                  <option key={key} value={y}>
                    {y}
                  </option>
                ))}
              </select>
              <input
                type="number"
                id="month"
                value={month || ''}
                className="form-control me-3"
                placeholder="MM"
                min="1"
                max="12"
                onChange={(e) => setMonth(e.target.value)}
              />
              <input
                type="number"
                value={day || ''}
                className="form-control me-3"
                placeholder="DD"
                min="1"
                max="31"
                onChange={(e) => setDay(e.target.value)}
              />
            </div>
          </>
        )}
        <div className={`btn btn-outline-danger`} onClick={clearPlayer}>
          <FontAwesomeIcon icon={faUserTimes} />
        </div>
      </div>
    </div>
  )
}

export default Player
