import React from 'react'
import { useMediaQuery } from 'react-responsive'
import braveMind from 'images/bravemind.png'
import Sponsor from './sponsor'

const Sponsors = ({}) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 576px)' })
  return (
    <div className="d-flex flex-column mb-3">
      <div
        className="d-flex align-items-center mb-3 p-2 justify-content-center mt-1"
        style={{
          height: '60px',
          fontSize: '0.9rem',
        }}
      >
        <a
          href="https://bravemind.co.uk"
          target="_blank"
          className="h-100 justify-content-start"
        >
          <img
            src={braveMind}
            className="img-fluid mh-100 align-items-center"
          />
        </a>
        <div className="ms-2">
          {isDesktop
            ? 'A percentage of all entry fees will be donated to '
            : 'Supporting '}
          <a href="https://bravemind.co.uk" target="_blank">
            Brave Mind
          </a>
        </div>
      </div>

      <div
        className="d-flex justify-content-between px-3"
        style={{ height: isDesktop ? '60px' : '50px' }}
      >
        <Sponsor sponsor="stPirans" />
        <Sponsor sponsor="madDogBrewery" />
        <Sponsor sponsor="clutterBuck" />
      </div>
    </div>
  )
}

export default Sponsors
