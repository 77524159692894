import React, { useState } from 'react'
import Fetch from 'Lib/fetch'

const Player = ({
  entry,
  player,
  isAdmin = false,
  onChange,
  showTeam = false,
}) => {
  const [checkedIn, setCheckedIn] = useState(
    player.checked_in_at ? true : false
  )

  const baseUrl = `/entries/${entry.id}/players/${player.id}`

  const updateCheckIn = () => {
    Fetch(baseUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        player: {
          checked_in_at: checkedIn ? '' : new Date().toISOString(),
        },
      }),
    }).then((response) => {
      if (response.ok) {
        response.json().then((updatedPlayer) => {
          setCheckedIn(updatedPlayer.checked_in_at ? true : false)
          if (onChange) onChange()
        })
      }
    })
  }

  return (
    <div className="list-group-item d-flex align-items-center">
      <div>
        {player.name} {player.dateOfBirth ? `(${player.date_of_birth})` : ''}
      </div>
      {showTeam && player.team_name && (
        <div className="ms-3 fw-bold">{player.team_name}</div>
      )}
      <div className="ms-auto form-check form-switch">
        <input
          type="checkbox"
          className="form-check-input"
          id={`player${player.id}CheckedIn`}
          checked={checkedIn}
          disabled={!isAdmin}
          onChange={updateCheckIn}
        />
        <label
          className="form-check-label"
          htmlFor={`player${player.id}CheckedIn`}
        >
          arrived
        </label>
      </div>
    </div>
  )
}

export default Player
