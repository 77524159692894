// simple wrapper around browser fetch

const Fetch = (resource, init) => {

  const options = {
    // Fetch options (defaults)
    credentials: 'same-origin',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': '',
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },

    ...init
  }

  // add csrf header
  if (typeof document !== 'undefined') {
    let token = document.getElementsByName('csrf-token')[0]
    if (token) options.headers['X-CSRF-Token'] = token.getAttribute('content')
  }

  return fetch(resource, options)
}

export default Fetch
