import React from 'react'
import { CookiesProvider } from 'react-cookie'
import BroadcastMessagesContainer from './container'

const BroadCastMessage = () => {
  return (
    <CookiesProvider>
      <BroadcastMessagesContainer />
    </CookiesProvider>
  )
}

export default BroadCastMessage
