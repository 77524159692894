import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Loading from 'Lib/loading'

const EditTeam = ({baseUrl, match, update, cancel, home = true}) => {
  const { data: availableTeams } = useSWR(`${baseUrl}/matches/${match.id}/alternatives`, Fetcher)

  if (!availableTeams) return <Loading message="" />

  const options = availableTeams && home ? availableTeams.home : availableTeams.away
  const selectedTeam = home ? match.homeTeam : match.awayTeam

  const handleUpdate = (teamId) => {
    cancel()
    update(teamId, home)
  }

  return (
    <div className="d-flex align-items-center">
      <select
        value={selectedTeam.id}
        className="form-select"
        onChange={(e) => handleUpdate(e.target.value)}
      >
        <option value={selectedTeam.id}>{selectedTeam.teamName} [existing]</option>
        {options.map((team, key) =>
          <option key={key} value={team.id}>{team.team_name} ({team.matches})</option>
        )}
      </select>
      <div
        className="ms-2 badge bg-dark" style={ { cursor: 'pointer' } }
        onClick={cancel}
      >
        Cancel
      </div>
    </div>
  )
}

export default EditTeam
