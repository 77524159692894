import React, { useState } from 'react'
import isEqual from 'lodash.isequal'
import Phone from 'Lib/phone'
import Fetch from 'Lib/fetch'

const User = ({user, mutate, complete}) => {
  const [editing, setEditing] = useState(!complete)
  const [draft,setDraft] = useState({ ...user })
  const [saving,setSaving] = useState(false)

  const [phoneValid,setPhoneValid] = useState(complete)

  const isDirty = !isEqual(draft, user)
  const phone = draft.phone

  const hasMinimum = draft.first_name && draft.last_name && draft.phone && phoneValid

  const updateDraft = (updates) => {
    setDraft(draft => ({ ...draft, ...updates}))
  }

  const save = () => {
    setSaving(true)
    Fetch('/me', {
      method: 'PATCH',
      body: JSON.stringify({
        user: {
          first_name: draft.first_name,
          last_name: draft.last_name,
          phone: draft.phone
        }
      })
    }).then(response => {
      setSaving(false)
      if (response.ok) {
        mutate()
        setEditing(false)
      }
    })
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        Your contact details
      </div>
      <div className="card-body">
        <div className="form-floating mb-3">
          <input type="text" className="form-control" value={currentUser.email} disabled/>
          <label >Email</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className={`form-control ${!editing && 'disabled'}`}
            defaultValue={draft.first_name}
            onChange={(e) => updateDraft({first_name: e.target.value})} 
            disabled={!editing}
          />
          <label >First Name</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className={`form-control ${!editing && 'disabled'}`}
            defaultValue={draft.last_name} 
            onChange={(e) => updateDraft({last_name: e.target.value})}
            disabled={!editing}
          />
          <label >Last Name</label>
        </div>
        <div className="form-floating">
          {editing &&
            <Phone
              phone={phone}
              update={(newPhone) => updateDraft({phone: newPhone})}
              setValid={setPhoneValid}
              disabled={!editing}
            />
          }
          {!editing &&
            <input type="text" className="form-control" value={draft.phone} disabled/>
          }
          <label >Mobile</label>
        </div>
      </div>
      <div className="card-footer">
        {editing &&
          <div
            className={`btn btn-success btn-sm ${(!isDirty || !hasMinimum || saving) && 'disabled'}`}
            onClick={save}
          >
            Save details and continue
          </div>
        }
        {!editing &&
          <div
            className={`btn btn-outline-secondary btn-sm`}
            onClick={() => setEditing(true)}
          >
            Edit my details
          </div>
        }
      </div>
    </div>
  )
}

export default User
