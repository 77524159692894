import React, { useState } from 'react'
import Fetch from 'Lib/fetch'
import EmailValidator from 'email-validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserHardHat } from '@fortawesome/pro-duotone-svg-icons'
import AdminsDetail from './detail'

const TournamentAdmins = ({ tournament, mutateTournament }) => {
  const [adminEmail, setAdminEmail] = useState(null)

  const addAdmin = () => {
    Fetch(`/tournaments/${tournament.id}/add_administrator`, {
      method: 'POST',
      body: JSON.stringify({ email: adminEmail.toLowerCase() }),
    }).then((response) => {
      if (response.ok) {
        setAdminEmail(null)
        mutateTournament()
      } else {
        response
          .json()
          .then((body) => alert(`Could not add user as admin: ${body.errors}`))
      }
    })
  }

  return (
    <div>
      <div className="flex flex-col">
        <a
          href="#"
          className="btn btn-sm btn-dark me-0 me-sm-2 mb-3 mb-sm-0"
          data-bs-toggle="modal"
          data-bs-target="#adminUser"
        >
          <FontAwesomeIcon icon={faUserHardHat} className="me-2" />
          Add admin...
        </a>
        <div className="modal fade" id="adminUser" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add a {tournament.name} admin</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Enter the email of the person you want to add as an admoin.
                </p>
                <p>They'll be sent an email confirming they've been added.</p>
                <input
                  type="email"
                  value={adminEmail || ''}
                  className="form-control"
                  onChange={(e) => setAdminEmail(e.target.value)}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className={`btn btn-primary ${
                    !EmailValidator.validate(adminEmail) && 'disabled'
                  }`}
                  onClick={addAdmin}
                  data-bs-dismiss="modal"
                >
                  Add & Send Email
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 flex flex-col space-y-2">
        <div className="font-semibold mb-2">
          {tournament.admins.length > 0
            ? 'Current Admins'
            : 'There are no admins specified as yet'}
        </div>
        {tournament.admins.map((admin) => (
          <AdminsDetail
            key={admin.id}
            admin={admin}
            tournament={tournament}
            mutateTournament={mutateTournament}
          />
        ))}
      </div>
    </div>
  )
}

export default TournamentAdmins
