import React, { useState } from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Fetch from 'Lib/fetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleNotch,
  faCogs,
  faPlusCircle,
  faTrashAlt,
} from '@fortawesome/pro-duotone-svg-icons'
import Pitch from './pitch'
import { orderBy } from 'lodash'
import Loading from 'Lib/loading'
import FixtureCheck from './fixture-check'
import Confirm from 'Lib/confirm'

const Pitches = ({ tournament, mutateTournament }) => {
  const [editing, setEditing] = useState(false)
  const [generating, setGenerating] = useState(false)
  const [removing, setRemoving] = useState(false)
  const baseUrl = `/tournaments/${tournament.id}`
  const { data: pitches, mutate } = useSWR(`${baseUrl}/pitches`, Fetcher)
  const { data: allEntriesIncludingDraft, mutate: mutateAll } = useSWR(
    `${baseUrl}/entries`,
    Fetcher
  )

  const mutateEverything = () => {
    mutate()
    mutateAll()
    mutateTournament()
  }

  const allEntries =
    allEntriesIncludingDraft &&
    allEntriesIncludingDraft.filter((e) => e.entered)

  const { data: currentUser } = useSWR('/me', Fetcher)

  let entered = []
  pitches &&
    pitches.forEach((pitch) => {
      pitch.entries.forEach((entry) => {
        entered.push(entry.id)
      })
    })

  let matches = []
  pitches &&
    pitches.forEach((pitch) => {
      pitch.matches.forEach((match) => {
        matches.push(match.id)
      })
    })

  const availableTeams =
    allEntries &&
    orderBy(
      allEntries.filter((e) => !entered.includes(e.id)),
      ['teamName']
    )

  const addPitch = () => {
    Fetch(`${baseUrl}/pitches`, {
      method: 'POST',
      body: JSON.stringify({
        pitch: {
          name: `${tournament.name} - ${pitches ? pitches.length + 1 : 1}`,
        },
      }),
    }).then((response) => {
      if (response.ok) {
        mutate()
      } else {
        response
          .json()
          .then((body) => alert(`An Error occurred: ${body.errors}`))
      }
    })
  }

  const generateFixtures = () => {
    setGenerating(true)
    Fetch(`${baseUrl}/generate_fixtures`, {
      method: 'POST',
    })
      .then((response) => {
        setGenerating(false)
        if (response.ok) {
          mutate()
          mutateTournament()
        } else {
          response
            .json()
            .then((body) => alert(`An Error occurred: ${body.errors}`))
        }
      })
      .catch((data) => {
        setGenerating(false)
        alert(`An error occurred`)
      })
  }

  const removeAllFixtures = () => {
    Confirm({
      title: 'Remove all fixtures?',
      message:
        'Are you sure you want to remove all the fixtures? There is no undo.',
      confirmText: 'Remove all',
      confirmColour: 'danger',
      icon: faTrashAlt,
      onConfirm: () => {
        setRemoving(true)
        Fetch(`${baseUrl}/remove_fixtures`, {
          method: 'POST',
        })
          .then((response) => {
            setRemoving(false)
            if (response.ok) {
              mutate()
              mutateTournament()
            } else {
              response
                .json()
                .then((body) => alert(`An Error occurred: ${body.errors}`))
            }
          })
          .catch(() => {
            setRemoving(false)
            alert(`An error occurred`)
          })
      },
    })
  }

  const [publishing, setPublishing] = useState(false)
  const setPublished = (status) => {
    setPublishing(true)
    Fetch(`${baseUrl}/${status ? 'publish' : 'unpublish'}`, {
      method: 'POST',
    }).then((response) => {
      if (response.ok) {
        mutate()
        mutateTournament()
        setPublishing(false)
      } else {
        setPublishing(false)
        response
          .json()
          .then((body) => alert(`An Error occurred: ${body.errors}`))
      }
    })
  }

  return (
    <div>
      {currentUser && currentUser.fixture_admin && (
        <div>
          <div className="alert alert-info mb-3">
            Note fixtures are DRAFT until the tournament starts
          </div>
          <div className="d-flex align-items-center">
            {!editing && (
              <button
                className="rounded border px-2 py-1 mr-2 bg-white"
                onClick={() => setEditing(true)}
              >
                Edit fixtures
              </button>
            )}
            {pitches && pitches.length < tournament.num_pitches && (
              <div
                className="my-3 btn btn-sm btn-outline-secondary d-flex align-items-center"
                onClick={addPitch}
              >
                <FontAwesomeIcon icon={faPlusCircle} className="me-2" />
                Add pitch
              </div>
            )}

            {matches.length > 0 && (
              <button
                className="ms-2 my-3 btn btn-sm btn-outline-danger d-flex align-items-center text-nowrap"
                onClick={removeAllFixtures}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" />
                Delete all fixtures
              </button>
            )}
            {pitches &&
              pitches.length > 0 &&
              entered.length > 0 &&
              matches.length === 0 &&
              !generating && (
                <div
                  className="ms-2 my-3 btn btn-sm btn-outline-secondary d-flex align-items-center"
                  onClick={generateFixtures}
                >
                  <FontAwesomeIcon icon={faCogs} className="me-2" />
                  Generate Fixtures
                </div>
              )}
            {generating && (
              <Loading className="ms-3" message="Generating fixtures..." />
            )}
            {removing && (
              <Loading
                className="ms-3 text-danger"
                message="Removing all fixtures..."
              />
            )}
            <div className="d-flex ms-auto align-items-center">
              {publishing && (
                <FontAwesomeIcon icon={faCircleNotch} className="me-2" spin />
              )}
              {tournament.fixtures_published ? (
                <div
                  className={`btn btn-sm btn-success ${
                    publishing ? 'disabled' : ''
                  }`}
                  onClick={() => setPublished(false)}
                >
                  Fixtures Published
                </div>
              ) : (
                <div
                  className={`btn btn-sm btn-warning ${
                    publishing ? 'disabled' : ''
                  }`}
                  onClick={() => setPublished(true)}
                >
                  Fixtures Not Published
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!pitches && <Loading />}
      {pitches &&
        pitches.length > 0 &&
        pitches.map((pitch, key) => (
          <Pitch
            baseUrl={baseUrl}
            availableTeams={availableTeams}
            pitch={pitch}
            key={key}
            mutatePitches={mutateEverything}
            admin={currentUser.fixture_admin && editing}
          />
        ))}
      {pitches && pitches.length === 0 && (
        <div className="alert alert-info my-3">
          There are no pitches allocated as yet
        </div>
      )}
      {currentUser.fixture_admin && <FixtureCheck tournament={tournament} />}
    </div>
  )
}

export default Pitches
