import React, { useState } from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Player from 'Lib/player'
import orderBy from 'lodash.orderby'

const PlayerCheckIn = ({ tournament, mutateTournament }) => {
  const [searchString, setSearchString] = useState('')
  const { data: players, mutate } = useSWR(
    `/tournaments/${tournament.id}/players`,
    Fetcher
  )

  const toCheckIn = players
    ?.filter((p) => !p.checked_in_at)
    ?.filter((p) =>
      searchString
        ? p.name.toLowerCase().includes(searchString.toLowerCase())
        : true
    )

  const handleUpdates = () => {
    mutateTournament()
    mutate()
  }

  return (
    <div>
      <div className="mb-3">
        {toCheckIn?.length === 0 && !searchString
          ? 'All players have been checked in'
          : 'Players yet to be checked in are shown below'}
      </div>
      {(toCheckIn?.length > 0 || searchString) && (
        <input
          className="form-control mb-3"
          placeholder="Filter by name"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      )}
      {searchString && toCheckIn?.length === 0 && (
        <div className="alert alert-info my-3 text-muted d-flex align-items-center">
          No players to check in match '{searchString}' try retyping{' '}
          <button
            className="ms-auto btn btn-sm btn-outline-secondary"
            onClick={() => setSearchString('')}
          >
            Clear search string
          </button>
        </div>
      )}
      <div className="d-flex flex-column">
        {toCheckIn &&
          orderBy(toCheckIn, ['name']).map((player) => (
            <Player
              player={player}
              isAdmin={true}
              entry={player.entry}
              onChange={handleUpdates}
              key={player.id}
              showTeam={true}
            />
          ))}
      </div>
    </div>
  )
}

export default PlayerCheckIn
