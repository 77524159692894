import { faPeopleGroup } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

const Entries = ({ entries }) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(document.location.pathname === '/entries')
  }, [])

  return (
    <div className="nav-item">
      <a
        className={`nav-link ${active && 'active'} d-flex align-items-center`}
        href="/entries"
      >
        <FontAwesomeIcon icon={faPeopleGroup} className="me-1" />
        Teams ({entries.length})
      </a>
    </div>
  )
}

export default Entries
