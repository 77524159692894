import Fetch from 'Lib/fetch'

const addNewMatch = ({
  baseUrl,
  homeTeamId,
  awayTeamId,
  pitchId,
  isBreak = false,
  onSuccess,
  onFailure,
}) => {
  Fetch(`${baseUrl}/matches`, {
    method: 'POST',
    body: JSON.stringify({
      match: {
        home_team_id: homeTeamId,
        away_team_id: awayTeamId,
        pitch_id: pitchId,
        break: isBreak,
      },
    }),
  }).then((response) => {
    if (response.ok) {
      if (onSuccess) onSuccess()
    } else {
      response.json().then((body) => {
        if (onFailure) {
          onFailure(body)
        } else {
          alert(`Could not create match: ${body.errors}`)
        }
      })
    }
  })
}

export default addNewMatch
