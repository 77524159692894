import { faPlusCircle } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Select from 'react-select'
import { format, parseISO } from 'date-fns'
import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import addNewMatch from './add-new-match'
import orderBy from 'lodash.orderby'

const NewSlot = ({ baseUrl, pitch, mutate }) => {
  const [adding, setAdding] = useState(false)
  const [homeTeam, setHomeTeam] = useState(null)
  const [saving, setSaving] = useState(false)

  const { data: nextSlot, mutate: mutateNextSlot } = useSWR(
    `${baseUrl}/pitches/${pitch.id}/next_slot`,
    Fetcher
  )

  useEffect(() => {
    mutateNextSlot()
  }, [adding])

  const availableTeamIdsForSlot = nextSlot
    ? nextSlot.available_teams.map((t) => t.id)
    : []

  const otherSlotInfo = (team) => {
    if (!nextSlot) return

    const twoSlotsBefore =
      team.playing_slots.includes(nextSlot.position - 2) &&
      team.playing_slots.includes(nextSlot.position - 1)
    const twoSlotsAfter =
      team.playing_slots.includes(nextSlot.position + 2) &&
      team.playing_slots.includes(nextSlot.position + 1)
    const oneSlotBeforeAndAfter =
      team.playing_slots.includes(nextSlot.position - 1) &&
      team.playing_slots.includes(nextSlot.position + 1)

    if (oneSlotBeforeAndAfter) return ' - slots before and after'
    if (twoSlotsBefore) return ' - 2 slots before'
    if (twoSlotsAfter) return '- 2 slots after'

    return ''
  }

  const teamOptions =
    nextSlot &&
    orderBy(
      nextSlot.available_teams.map((t) => ({
        label: `${t.team_name}${otherSlotInfo(t)}`,
        value: t.id,
        teamName: t.team_name,
      })),
      ['label']
    )

  const { data: availableAwayTeams } = useSWR(
    homeTeam ? `${baseUrl}/entries/${homeTeam.value}/to-play` : null,
    Fetcher
  )

  const availableAwayTeamIds = availableAwayTeams?.map((team) => team.id) || []

  const awayTeamOptions =
    (homeTeam &&
      teamOptions?.filter((t) => {
        return (
          availableAwayTeamIds.includes(t.value) &&
          availableTeamIdsForSlot.includes(t.value) &&
          t.value !== homeTeam.value
        )
      })) ||
    []

  const addMatch = (awayTeam) => {
    setSaving(true)
    addNewMatch({
      baseUrl: baseUrl,
      homeTeamId: homeTeam.value,
      awayTeamId: awayTeam.value,
      pitchId: pitch.id,
      onSuccess: () => {
        setAdding(false)
        setHomeTeam(null)
        mutate()
        mutateNextSlot()
        setSaving(false)
      },
    })
  }

  const addBreak = () => {
    setSaving(true)
    addNewMatch({
      baseUrl: baseUrl,
      isBreak: true,
      pitchId: pitch.id,
      onSuccess: () => {
        setAdding(false)
        setHomeTeam(null)
        mutate()
        mutateNextSlot()
        setSaving(false)
      },
      onFailure: (body) => {
        setSaving(false)
        alert(`Could not add: ${body.errors}`)
      },
    })
  }

  return (
    <div className="px-3 py-2">
      {saving ? (
        <FontAwesomeIcon icon={faCircleNotch} spin />
      ) : (
        <>
          {adding && nextSlot ? (
            <div className="w-100 d-flex align-items-center">
              <span className="me-2">
                {format(parseISO(nextSlot.time), 'kk:mm')} :
              </span>
              <Select
                options={teamOptions}
                value={homeTeam}
                onChange={setHomeTeam}
                isClearable={true}
                placeholder="Select a Home Team"
              />
              {homeTeam && (
                <>
                  <span className="mx-2">vs</span>
                  {homeTeam && awayTeamOptions.length > 0 ? (
                    <Select
                      options={awayTeamOptions}
                      placeholder="Select An Away Team"
                      onChange={addMatch}
                    />
                  ) : (
                    <div>
                      {homeTeam && availableAwayTeams ? (
                        `No available opponents for ${homeTeam.teamName}`
                      ) : homeTeam ? (
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                      ) : (
                        `Select a home team`
                      )}
                    </div>
                  )}
                </>
              )}
              <div
                className="ms-auto btn btn-sm btn-outline-secondary"
                onClick={addBreak}
              >
                Add as break
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={faPlusCircle}
                onClick={() => setAdding(true)}
                className="btn me-2"
              />
              <span>Add next match</span>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default NewSlot
