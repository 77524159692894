import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Loading from 'Lib/loading'
import Fixture from '../shared/fixture'

const TeamFixtures = ({teamId, fixturesUrl}) => {
  const { data: fixtures, error } = useSWR(fixturesUrl, Fetcher)

  if (fixtures) {
    return (
      <div className="table-responsive-sm">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Time</th>
              <th>Pitch</th>
              <th>Opposition</th>
            </tr>
          </thead>
          <tbody>
            {fixtures.map((fixture, key) =>
              <Fixture fixture={fixture} filterTeamId={teamId} key={key}/>
            )}
          </tbody>
        </table>
      </div>
    )
  } else {
    if (error) {
      return <div className="alert alert-danger">An error occurred</div>
    } else {
      return <Loading message="loading fixtures" />
    }
  }
  
}

export default TeamFixtures
