import React from 'react'
import madDogBrewery from 'images/sponsors/mad-dog-brewery.jpg'
import stPirans from 'images/sponsors/st-pirans.png'
import clutterBuck from 'images/sponsors/clutterbuck.png'
import { useMediaQuery } from 'react-responsive'

const SPONSORS = {
  madDogBrewery: {
    image: madDogBrewery,
    url: 'https://maddogbrew.co.uk',
    mobileWidth: '50%',
  },
  stPirans: {
    image: stPirans,
    url: 'https://stpirans.co.uk',
    mobileWidth: '100%',
  },
  clutterBuck: {
    image: clutterBuck,
    url: 'https://clutterbuck-cmi.com/proud-sponsor-of-maidenhead-rugby-club-minis-festival/',
    mobileWidth: '70%',
  },
}

const Sponsor = ({ sponsor }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 576px)' })
  const { url, image, mobileWidth } = SPONSORS[sponsor]

  if (!url || !image) return null

  return (
    <a
      href={url}
      target="_blank"
      className="mh-100 d-flex align-items-center justify-content-center"
      style={{ width: `${90 / Object.keys(SPONSORS).length}%` }}
    >
      <img
        src={image}
        className="align-items-center object-fit-contain mh-100"
        style={
          isDesktop || !mobileWidth ? { width: 'auto' } : { width: mobileWidth }
        }
      />
    </a>
  )
}

export default Sponsor
