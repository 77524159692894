import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import { faTrophyStar } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Tournaments = ({}) => {
  const [active, setActive] = useState(false)
  const { data: tournaments } = useSWR('/tournaments', Fetcher)

  useEffect(() => {
    setActive(document.location.pathname === '/tournaments')
  }, [])

  if (tournaments && tournaments.length > 0) {
    return (
      <div className="nav-item">
        <a
          className={`nav-link ${active && 'active'} d-flex align-items-center`}
          href="/tournaments"
        >
          <FontAwesomeIcon icon={faTrophyStar} className="me-1" />
          Tournaments
        </a>
      </div>
    )
  } else return null
}

export default Tournaments
