import {
  faCheckDouble,
  faConciergeBell,
  faFootballBall,
  faPersonWalking,
  faScrewdriverWrench,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const TabBar = ({ currentTab, setTab, tournament }) => {
  const tabs = {
    teams: 'Checked in',
    checkIn: 'Team check in',
    playerCheckIn: 'Player Check in',
    pitches: 'Fixtures',
    admins: 'Admins',
  }

  const mobileTabs = {
    checkIn: <FontAwesomeIcon icon={faConciergeBell} />,
    playerCheckIn: <FontAwesomeIcon icon={faPersonWalking} />,
    teams: <FontAwesomeIcon icon={faCheckDouble} />,
    pitches: <FontAwesomeIcon icon={faFootballBall} />,
    admins: <FontAwesomeIcon icon={faScrewdriverWrench} />,
  }

  const badges = {
    checkIn: tournament.not_checked_in,
    teams: tournament.checked_in,
    pitches: tournament.matches,
    playerCheckIn: tournament.not_checked_in_players,
    admins: tournament.admins?.length,
  }

  if (tournament.not_checked_in === 0) {
    delete tabs.checkIn
    delete tabs.playerCheckIn
  }

  return (
    <ul className="nav nav-tabs nav-fill mb-3">
      {Object.entries(tabs).map(([tab, label], key) => (
        <li className="nav-item" key={key}>
          <a
            className={`nav-link  d-flex justify-content-center ${
              tab === currentTab && 'active'
            }`}
            href="#"
            onClick={() => setTab(tab)}
          >
            <div className="d-none d-sm-flex align-items-center">
              {mobileTabs[tab]}
              <span className="ms-2">{label}</span>
              {parseInt(badges[tab]) > 0 && (
                <div
                  className={`badge ${
                    ['checkIn', 'playerCheckIn'].includes(tab)
                      ? 'bg-danger'
                      : 'bg-dark'
                  } ms-2`}
                >
                  {badges[tab]}
                </div>
              )}
            </div>
            <div className="d-flex d-sm-none flex-sm-row align-items-center">
              {mobileTabs[tab]}
              <span
                className={`badge ${
                  tab === 'checkIn' ? 'bg-danger' : 'bg-dark'
                } ms-2`}
                style={{ fontSize: '0.8rem' }}
              >
                {badges[tab]}
              </span>
            </div>
          </a>
        </li>
      ))}
    </ul>
  )
}

export default TabBar
