import { faShoppingBasket } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import useSWR from 'swr'
import Fetcher from '../lib/fetcher'

const Basket = () => {
  const { data: entries } = useSWR('/entries/not_entered', Fetcher)

  if (entries && entries.length > 0) {
    return (
      <a className="text-light fa-layers fa-fw" style={ { fontSize: '1.5rem' } } href="/checkout">
        <FontAwesomeIcon icon={faShoppingBasket} />
        <span className="fa-layers-counter bg-danger" style={ { transform: 'scale(0.4)'}}>{entries.length}</span>
      </a>
    )
  } else return null
}

export default Basket
