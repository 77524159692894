import React from 'react'

const TournamentEdit = ({tournamentId}) => {
  return (
    <div>
      Tournament edit
    </div>
  )
}

export default TournamentEdit
