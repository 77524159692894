import React, { useEffect, useState } from 'react'
import TabBar from './tab-bar'
import TeamCheckIn from './team-check-in'
import Fetcher from 'Lib/fetcher'
import Loading from 'Lib/loading'
import useSWR from 'swr'
import Pitches from './pitches'
import CheckedInTeams from './checked-in-teams'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel, faUserHardHat } from '@fortawesome/pro-duotone-svg-icons'
import TournamentAdmins from './admins'
import history from 'history/browser'
import PlayerCheckIn from './player-check-in'

const TournamentEdit = ({ tournamentId, selectedTab = 'teams' }) => {
  const { data: tournament, mutate } = useSWR(
    `/tournaments/${tournamentId}`,
    Fetcher
  )
  const [tab, setTab] = useState(selectedTab)

  useEffect(() => {
    history.push(history.location.pathname.split('?')[0] + `?tab=${tab}`)
  }, [tab])

  if (tournament && tournament.not_checked_in === 0 && tab === 'checkIn')
    setTab('teams')

  return (
    <div className="mb-3">
      {!tournament && <Loading />}
      {tournament && (
        <>
          <div className="my-3 d-none d-sm-flex flex-column flex-sm-row align-items-sm-center justify-content-end">
            <a
              href={`/tournaments/${tournamentId}.csv`}
              className="btn btn-sm btn-dark me-0 me-sm-2 mb-3 mb-sm-0"
            >
              <FontAwesomeIcon icon={faFileExcel} className="me-2" />
              Download entries
            </a>
            <a
              href={`/matches.csv?tournament=${tournamentId}`}
              className="btn btn-sm btn-dark mb-3 mb-sm-0"
            >
              <FontAwesomeIcon icon={faFileExcel} className="me-2" />
              Download fixtures
            </a>
          </div>
          <TabBar currentTab={tab} setTab={setTab} tournament={tournament} />
          {tab === 'checkIn' && (
            <TeamCheckIn tournament={tournament} mutateTournament={mutate} />
          )}
          {tab === 'pitches' && (
            <Pitches tournament={tournament} mutateTournament={mutate} />
          )}
          {tab === 'teams' && (
            <CheckedInTeams tournament={tournament} mutateTournament={mutate} />
          )}
          {tab === 'admins' && (
            <TournamentAdmins
              tournament={tournament}
              mutateTournament={mutate}
            />
          )}
          {tab === 'playerCheckIn' && (
            <PlayerCheckIn tournament={tournament} mutateTournament={mutate} />
          )}
        </>
      )}
    </div>
  )
}

export default TournamentEdit
