import React, { useEffect } from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import { orderBy } from 'lodash'

const FixtureFilter = ({filter, updateFilter}) => {
  const { data: tournaments } = useSWR(`tournaments/upcoming`, Fetcher)
  const pitchesUrl = `/tournaments${filter.tournament ? `/${filter.tournament}` : ''}/get-pitches`
  const { data: pitches } = useSWR(pitchesUrl, Fetcher)
  const searchString = new URLSearchParams(filter).toString()
  const teamsUrl = `/entries/for_filter?${searchString}`
  const { data: teams } = useSWR(teamsUrl, Fetcher)
  const sortedTeams = teams && orderBy(teams, ['tournament','name'])

  useEffect(() => {
    if (teams && filter.team && teams.length > 0) {
      if (!teams.some(t => parseInt(t.id) === parseInt(filter.team))) {
        updateFilter({ team: '-1'})
      }
    }
  }, [teams])

  return (
    <div className="d-flex flex-column flex-sm-row mb-sm-3">
      <select
        className="form-select mb-1 mb-sm-0 me-sm-1"
        value={filter.tournament}
        onChange={(e) => updateFilter({tournament: e.target.value})}
      >
        <option value="-1">All age groups</option>
        {tournaments && tournaments.map((tournament, key) =>
          <option value={tournament.id} key={key}>{tournament.name}</option>
        )}
      </select>
      <select
        className="form-select my-1 my-sm-0 mx-sm-1"
        value={filter.team}
        onChange={(e) => updateFilter({team: e.target.value})}
      >
        <option value="-1">All teams</option>
        {sortedTeams && sortedTeams.map((team, key) =>
          <option value={team.id} key={key}>
            {filter.tournament ? team.name : `${team.tournament} : ${team.name}`}
          </option>
        )}
      </select>
      <select
        className="form-select my-1 my-sm-0 ms-sm-1"
        value={filter.pitch}
        onChange={(e) => updateFilter({pitch: e.target.value})}
      >
        <option value="-1">All pitches</option>
        {pitches && pitches.map((pitch, key) =>
          <option value={pitch.id} key={key}>Pitch {pitch.name}</option>
        )}
      </select>
    </div>
  )
}

export default FixtureFilter
