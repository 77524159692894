import React, { useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import Fetch from 'Lib/fetch'
import Currency from 'Lib/currency'

const ConfirmPayment = ({ payment, mutate }) => {
  const [date, setDate] = useState(null)

  const logPayment = () => {
    Fetch(`/admin/payments/${payment.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        payment: {
          paid_at: date,
        },
      }),
    }).then((response) => {
      if (response.ok) {
        if (mutate) mutate()
      } else {
        alert(`Error - could not save payment info`)
      }
    })
  }

  return (
    <div className="p-3 d-flex flex-column border bg-light">
      <div className="d-flex align-items-center mb-2 justify-content-end pb-2 border-bottom">
        <div className="fw-bold me-auto">Payment details for bank</div>
        <div className="me-2 text-success">{payment.reference}</div>
        <Currency className="text-success" amount={payment.amount} />
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column">
          <label className="form-label">Enter date payment received</label>
          <DayPickerInput onDayChange={setDate} />
        </div>
        <div className="ms-auto align-self-end">
          <div
            className={`btn btn-success ${!date && 'disabled'}`}
            onClick={logPayment}
          >
            Confirm payment receieved
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmPayment
