import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import CreateEntry from './create-entry'
import CreateClub from './create-club'
import CreatableSelect from 'react-select/creatable' 
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'

const TournamentEntry = ({tournamentId, booker}) => {
  const { data: clubs, mutate, isValidating } = useSWR('/clubs', Fetcher)
  const [entry, setEntry] = useState({
    tournament_id: tournamentId,
    club_id: booker.club.id
 })
  const [saving, setSaving] = useState(false)
  const complete = entry.club_id

  const updateEntry = (changes) => {
    setEntry(entry => ({ ...entry, ...changes }))
  }

  const handleChange = (club) => {
    if (isNaN(parseInt(club.value))) {
      CreateClub(club.label)
        .then(response => {
          if (response.ok) {
            response.json().then(c => {
              mutate(clubs => [...clubs, c])
              updateEntry({ club_id: c.id })
            })
          } else {
            response.json().then(data => {
              alert(`Could not add ${club.label} to our list of clubs ${data.error}`)
            })
          }
        })
    } else {
      updateEntry({ club_id: club.value })
    }
  }

  const save = () => {
    CreateEntry(entry)
      .then(response => {
        if (response.ok) {
          document.location.href = '/tournaments'
        } else {
          response.json().then(data => alert(`An error occurred: ${data.error}`))
        }
      })
      .catch(error => alert(`An error occurred: ${JSON.stringify(error)}`))
  }

  return (
    <div className="card">
      <div className="card-header">Enter details for your entry</div>
      <div className="card-body">
        <div className="d-flex flex-column mb-3">
          <label >Club Name</label>
          <CreatableSelect
            isClearable
            options={clubs}
            value={clubs && clubs.find(c => c.value === entry.club_id)}
            placeholder="Enter club name..."
            onChange={handleChange}
            isDisabled={booker.club.id && booker.club.id === entry.club_id}
          />
        </div>
      </div>
      <div className="card-footer d-flex flex-column">
        <div
          className={`btn btn-sm btn-success ${(!complete || saving) && 'disabled'}`}
          onClick={save}
        >
          {saving &&
            <FontAwesomeIcon icon={faCircleNotch} spin className="me-2" />
          }
          Add to basket
        </div>
      </div>
    </div>
  )
}

export default TournamentEntry
