import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import Entry from 'Lib/entry'
import pluralize from 'pluralize'
import Payment from './payment'
import orderBy from 'lodash.orderby'

const Payments = ({}) => {
  const { data: payments } = useSWR('/payments', Fetcher)

  return (
    <div className="py-3 d-flex flex-column">
      {!payments && (
        <div className="d-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={faCircleNotch} spin className="me-2" />
          loading payment records...
        </div>
      )}
      {payments && (
        <>
          {payments.length === 0 && (
            <div className="alert alert-info">
              You have not placed any orders for tournament entry.
            </div>
          )}
          {payments.length > 0 && (
            <>
              <div className="px-3">Your orders</div>
              {orderBy(payments, ['created_at'], ['desc']).map(
                (payment, key) => (
                  <div className="mb-1" key={key}>
                    <Payment payment={payment} />
                  </div>
                )
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Payments
