import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Loading from 'Lib/loading'

const PhotoRegistrations = () => {
  const { data: registrations } = useSWR('/admin/photo_registrations', Fetcher)
  return (
    <div>
      {!registrations && <Loading message="loading photo registrations" className="my-3" />}
      {registrations &&
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <th>Name</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Purpose</th>
              <th>Agreed at</th>
            </thead>
            <tbody>
            {registrations.map((registration, key) =>
              <tr key={key}>
                <td>{registration.user.firstName} {registration.user.lastName}</td>
                <td>{registration.user.phone}</td>
                <td>{registration.user.email}</td>
                <td>{registration.photographyType}</td>
                <td>{registration.agreedAt}</td>
              </tr>
            )}
          </tbody>
          </table>
        </div>
      }
    </div>
  )
}

export default PhotoRegistrations
