import React, { useEffect, useState } from 'react'
import useSWR from 'swr'
import AdminUser from './admin-user'
import NewAdmin from './new-admin'
import Fetcher from 'Lib/fetcher'
import Loading from 'Lib/loading'
import Players from './players'
import { isEqual } from 'lodash'
import objectForRails from 'Lib/object-for-rails'
import Team from './team'
import Fetch from 'Lib/fetch'

const EntryEdit = ({ entryUrl, isAdmin = false }) => {
  const [addAdmin, setAddAdmin] = useState(false)
  const [draft, setDraft] = useState(null)
  const { data: entry, mutate, error } = useSWR(entryUrl, Fetcher)
  const [isDirty, setIsDirty] = useState(draft && !isEqual(entry, draft))

  useEffect(() => {
    setIsDirty(draft && !isEqual(entry, draft))
  }, [draft, entry])

  const validKeys = [
    'players',
    'coach',
    'coachPhone',
    'referee',
    'refereePhone',
    'firstAider',
    'firstAiderPhone',
  ]

  const attributeKeys = ['players']

  const updateAdmins = () => {
    setAddAdmin(false)
    mutate()
  }

  const updateDraft = (changes) => {
    setDraft({ ...draft, ...changes })
  }

  useEffect(() => {
    if (entry) {
      setDraft({ ...entry })
    }
  }, [entry])

  const save = () => {
    Fetch(entryUrl, {
      method: 'PATCH',
      body: JSON.stringify({
        entry: objectForRails(draft, validKeys, attributeKeys),
      }),
    }).then((response) => {
      if (response.ok) {
        mutate()
      } else {
        response
          .json()
          .then((body) => alert(`An error occurred: ${body.errors}`))
      }
    })
  }

  return (
    <>
      <div className="p-3">
        <div className="m-3 d-flex flex-column flex-sm-row align-items-sm-center">
          {isDirty && <span className="me-2 text-danger">unsaved changes</span>}
          <div
            className={`ms-auto btn btn-sm btn-success ${
              !isDirty && 'disabled'
            }`}
            onClick={save}
          >
            Save changes
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-header d-flex align-items-center">
            Team administrators
            <div
              className="ms-auto btn btn-sm btn-outline-secondary"
              onClick={() => setAddAdmin(true)}
            >
              Add...
            </div>
          </div>
          {addAdmin && (
            <NewAdmin url={entryUrl} entry={entry} callback={updateAdmins} />
          )}
          {!draft && !error && <Loading />}
          {error && <div className="alert alert-danger">An error ocurred</div>}
          {draft && !error && (
            <>
              <div className="list-group">
                <AdminUser user={entry.bookedBy} suffix="[Booker]" />
                {draft.administrators.map((admin, key) => (
                  <AdminUser user={admin} key={key} />
                ))}
              </div>
            </>
          )}
        </div>
        {draft && <Team entry={draft} updateDraft={updateDraft} />}
        {draft && (
          <Players
            entry={draft}
            updateDraft={updateDraft}
            validYears={entry.valid_years}
          />
        )}
      </div>
    </>
  )
}

export default EntryEdit
