import { faSave } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEqual } from 'lodash'
import React, { useState } from 'react'
import Fetch from 'Lib/fetch'
import objectForRails from 'Lib/object-for-rails'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { parseISO, formatISO } from 'date-fns'
import { enGB } from 'date-fns/locale'
registerLocale('gb', enGB)

const EditBroadcastMessage = ({message}) => {
  const baseUrl = `/admin/broadcast_messages`
  const [original,setOriginal] = useState(message)
  const [draft,setDraft] = useState(message)

  const levels = ['info', 'warning', 'danger', 'dark']
  const isDirty = !isEqual(original, draft)
  const canSave = draft.subject && draft.message

  const updateDraft = (updates) => {
    setDraft(d => ({ ...draft, ...updates}))
  }

  const levelLabels = {
    info: 'Lght blue',
    warning: 'Light yellow',
    danger: 'Light Red backgroud',
    dark: 'Gray background'
  }

  const save = () => {
    const url = draft.id ? `${baseUrl}/${draft.id}` : baseUrl
    Fetch(url, {
      method: draft.id ? 'PATCH' : 'POST',
      body: JSON.stringify({
        broadcast_message: objectForRails(draft)
      })
    }).then(response => {
      if (response.ok) {
        setOriginal({ ...draft })
      } else {
        response.json().then(body => alert(`An error occurred: ${body.errors}`))
      }
    })
  }

  return (
    <div className="card py-3">
      <div className="card-header d-flex align-items-center">
        {draft.subject || 'New Message'}
        {isDirty && <span className="ms-2 text-danger">unsaved changes</span>}
        <div
          className={`ms-auto btn btn-success ${(!isDirty || !canSave) && 'disabled'}`}
          onClick={save}
        >
          <FontAwesomeIcon icon={faSave} className="me-2" />
          Save changes
        </div>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <label className="form-label">Subject</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter subject for message"
            value={draft.subject || ''}
            onChange={e => updateDraft({ subject: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Message details</label>
          <textarea
            value={draft.message || ''}
            onChange={e => updateDraft({ message: e.target.value })}
            className="form-control"
            rows="3"
            placeholder="Message text"
          ></textarea>
        </div>
        <div className="mb-3">
          <label className="form-label">Message format</label>
          <select
            className="form-control" value={message.level}
            onChange={e => updateDraft({ level: e.target.value })}
          >
            {levels.map((level, key) =>
              <option key={key} value={level}>{levelLabels[level]}</option>
            )}
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Publish at</label>
          <DatePicker
            selected={draft.publishAt ? parseISO(draft.publishAt) : null}
            onChange={date => updateDraft({ publishAt: formatISO(date)})}
            placeholderText="Click to select date/time"
            locale="gb"
            dateFormat="Pp"
            showTimeSelect
            withPortal
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Publish until</label>
          <DatePicker
            selected={draft.publishUntil ? parseISO(draft.publishUntil) : null}
            onChange={date => updateDraft({ publishUntil: formatISO(date)})}
            placeholderText="Click to select date/time"
            locale="gb"
            dateFormat="Pp"
            showTimeSelect
            withPortal
          />
        </div>
      </div>
    </div>
  )
}

export default EditBroadcastMessage
