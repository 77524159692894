import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Fetch from 'Lib/fetch'
import logo from 'Images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBagsShopping,
  faBars,
  faCameraAlt,
  faComments,
  faFootballBall,
  faPerson,
  faPoundSign,
  faSignOut,
  faUserAlt
} from '@fortawesome/pro-duotone-svg-icons'
import styles from './index.module.scss'
import Basket from './basket'
import Entries from './entries'
import Tournaments from './tournaments'
import AwaitingPayment from './awaiting-payment'
import DropDownItem from './drop-down-item'
import TournamentsAdmin from './tournaments-admin'
import { useMediaQuery } from 'react-responsive'

const logout = ({ loggedIn }) => {
  Fetch('/users/sign_out', {
    method: 'DELETE'
  })
    .then(response => {
      if (response.ok) {
        window.location.reload()
      } else {
        alert(`Could not log out: ${response.status} : ${response.statusText}`)
      }
    })
    .catch(error => {
      alert(`An error occurred: ${JSON.stringify(error)}`)
    })
}

const NavBar = ({
  loggedIn = false,
  title = '2022 Festival',
  subtitle = ''
}) => {
  const { data: currentUser } = useSWR(loggedIn ? '/me' : null, Fetcher)
  const isDesktop = useMediaQuery({ query: '(min-width: 576px)' })

  return (
    <>
      <nav className='navbar navbar-dark bg-dark navbar-expand-lg p-0'>
        <div className='container-fluid justify-content-center justify-content-sm-start pb-3 pb-md-0'>
          <div className='navbar-brand d-flex align-items-center'>
            <a href='/' className={`p-2 ${styles.logo}`}>
              <img src={logo} alt='MRFC Logo' className='img-fluid me-3' />
            </a>
          </div>
          <div className='d-flex flex-column align-items-start p-1 text-white'>
            <div className='text-center fw-bold'>
              {isDesktop ? `Maidenhead RUFC` : 'MRUFC'} Minis Festival{' '}
              {isDesktop ? '3rd November' : '3 Nov'} 2024
            </div>
          </div>
          <button
            className='navbar-toggler ms-auto'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#mainNavBar'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className='collapse navbar-collapse' id='mainNavBar'>
            <div className='navbar-nav w-100 justify-content-end align-items-center'>
              <Tournaments />
              <TournamentsAdmin
                tournamentIds={currentUser?.tournaments_as_admin}
              />
              {currentUser?.entries && currentUser?.entries?.length > 0 && (
                <Entries entries={currentUser.entries} />
              )}
              <div className='nav-item dropdown me-2'>
                {currentUser && (
                  <>
                    <a
                      className='nav-link dropdown-toggle'
                      href='#'
                      id='navbarDropdownMenuLink'
                      role='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <FontAwesomeIcon icon={faUserAlt} className='me-2' />
                      {currentUser.name}
                    </a>
                    <ul
                      className='dropdown-menu'
                      aria-labelledby='navbarDropdownMenuLink'
                    >
                      {currentUser.admin && (
                        <>
                          <li>
                            <h6 className='dropdown-header'>Admin</h6>
                          </li>
                          <DropDownItem
                            url='/admin/entries'
                            linkUrl='/admin/tournaments'
                            text='All entries'
                            icon={faFootballBall}
                          />
                          <DropDownItem
                            url='/admin/payments'
                            linkUrl='/admin/payments'
                            text='All payments made'
                            icon={faPoundSign}
                          />
                          <AwaitingPayment />
                          <DropDownItem
                            url='/admin/photo_registrations'
                            linkUrl='/admin/photo_registrations'
                            text='Photo Registrations'
                            icon={faCameraAlt}
                          />
                          <DropDownItem
                            url='/admin/broadcast_messages'
                            linkUrl='/admin/broadcast_messages'
                            text='Broadcast Messages'
                            icon={faComments}
                          />
                          <li>
                            <hr className='dropdown-divider' />
                          </li>
                        </>
                      )}
                      <li>
                        <h6 className='dropdown-header'>Your account</h6>
                      </li>
                      <DropDownItem
                        url='/orders'
                        linkUrl='/orders'
                        text='Your orders'
                        icon={faBagsShopping}
                      />
                      <li className='dropdown-item' onClick={logout}>
                        <FontAwesomeIcon icon={faSignOut} className='me-2' />
                        Log out
                      </li>
                    </ul>
                  </>
                )}
                {!loggedIn && (
                  <a
                    className='text-light d-flex align-items-center'
                    href='/users/sign_in'
                  >
                    <FontAwesomeIcon icon={faPerson} className='me-1' />
                    login
                  </a>
                )}
              </div>
              {currentUser && (
                <div className='nav-item me-2'>
                  <Basket />
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default NavBar
