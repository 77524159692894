// simple wrapper around browser fetch for swr
const options = {
  // Fetch options (defaults)
  credentials: 'same-origin',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': '',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

// add csrf header
if (typeof document !== 'undefined') {
  let token = document.getElementsByName('csrf-token')[0]
  if (token) options.headers['X-CSRF-Token'] = token.getAttribute('content')
}

const Fetcher = (url) => {
  return fetch(url, options).then((res) => res.json())
}

export default Fetcher
