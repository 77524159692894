import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationTriangle} from '@fortawesome/pro-duotone-svg-icons'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import './index.module.scss'

const Phone = ({phone, setValid, update, placeholder='Enter Mobile Number'}) => {

  useEffect(() => {
    if (phone && setValid) {
      setValid(isValidPhoneNumber(phone))
    }
  }, [phone])

  return(
    <>
      <div className="form-control d-flex align-items-center p-0">
        <PhoneInput
          className="form-control border-0" 
          value={phone}
          onChange={update}
          defaultCountry="GB"
          placeholder={placeholder}
          withCountryCallingCode={true}
        />
        {phone &&
          <div className="ms-auto me-3">
            {!isValidPhoneNumber(phone) ? 
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger"/>
              : <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
            }
          </div>
        }
      </div>
    </>
  )
}

export default Phone
