import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Loading from 'Lib/loading'
import FixtureFilter from './filter'
import FixtureList from './shared/list'
import { orderBy } from 'lodash'

const FixturesContainer = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['tournament', 'pitch', 'team'])
  const [filter,setFilter] = useState({})

  const validParams = Object.entries(filter).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})

  const searchString = new URLSearchParams(validParams).toString()

  const { data: fixtures, isValidating, error } = useSWR(`/matches?${searchString}`, Fetcher)

  const updateFilter = (updates) => {
    let revisedUpdates = {}
    Object.entries(updates).forEach(([key, value]) => {
      if (value === '-1') {
        revisedUpdates[key] = null
      } else {
        revisedUpdates[key] = value
      }
    })
    setFilter(f => ({ ...f, ...revisedUpdates }))
  }

  useEffect(() => {
    setFilter(f => ({ ...f, ...cookies }))
  }, [cookies])

  useEffect(() => {
    Object.entries(filter).forEach(([key, value]) => {
      if (value) {
        setCookie(key, value)
      } else {
        removeCookie(key)
      }
      
    })
  }, [filter])

  const sortedFixtures = fixtures && orderBy(fixtures, ['startTime','pitchName'])

  return (
    <div>
      <FixtureFilter filter={validParams} updateFilter={updateFilter} />
      {(!fixtures || isValidating) && <Loading />}
      {fixtures && <FixtureList fixtures={sortedFixtures} filter={validParams} />}
    </div>
  )
}

export default FixturesContainer
