import React, { useState } from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Fetch from 'Lib/fetch'
import Loading from 'Lib/loading'
import { orderBy } from 'lodash'
import Expander from 'Lib/expander'
import { format, parseISO } from 'date-fns'
import { enGB } from 'date-fns/locale'
import Entry from 'Lib/entry'
import Invoice from '../../invoice'
import Currency from 'Lib/currency'
import Confirm from 'Lib/confirm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import ConfirmPayment from './confirm-payment'

const AdminPaymentsList = ({ paid = true }) => {
  const [refunding, setRefunding] = useState(false)
  const {
    data: payments,
    mutate,
    isValidating,
  } = useSWR(`/admin/payments${paid ? '' : '/unpaid'}`, Fetcher)

  if (!payments) return <Loading message="loading payments list" />

  const paymentStatusStyle = {
    PAID: 'text-success',
    ['AWAITING PAYMENT']: 'text-warning',
    REFUNDED: 'text-danger',
  }

  const asCurrency = (amount) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    }).format(amount)
  }

  const refund = (payment) => {
    Confirm({
      title: 'Confirm Refund',
      message:
        `This will refund payment ${payment.reference} of ${asCurrency(
          payment.amount
        )}. ` +
        'It will also remove the entered teams from the tournaments. ' +
        'There is no undo so be sure you mean to do this before proceeding.',
      onConfirm: () => {
        setRefunding(true)
        Fetch(`/admin/payments/${payment.id}/refund`, {
          method: 'POST',
        }).then((response) => {
          if (response.ok) {
            mutate()
            setRefunding(false)
          } else {
            setRefunding(false)
            response
              .json()
              .then((body) => alert(`An error occurred: ${body.errors}`))
          }
        })
      },
      confirmText: 'Issue Refund Now',
    })
  }

  const TitleRow = ({ payment }) => {
    return (
      <div className="d-flex align-items-center">
        <div className="me-2">
          {format(parseISO(payment.created_at), 'kk:mm:ss P', {
            locale: enGB,
          })}
        </div>
        <div className="me-2">
          {payment.user?.club?.name || payment.user?.club}
        </div>
        <div className="me-2">{payment.user?.name}</div>
        <div className="me-2">
          <Currency amount={payment.amount} />
        </div>
        <div className="me-2">{payment.provider}</div>
        <div className={`me-2 ${paymentStatusStyle[payment.status]}`}>
          {payment.status}
        </div>

        <div className="ms-auto d-flex align-items-center">
          {(refunding || isValidating) && (
            <FontAwesomeIcon className="me-2" icon={faCircleNotch} spin />
          )}
          {payment.refundable && !payment.refunded && (
            <div
              className={`btn btn-sm btn-warning ${
                refunding || isValidating ? 'disabled' : ''
              }`}
              onClick={() => refund(payment)}
            >
              Refund
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {payments.length === 0 ? (
        <div className="p-3">There are no outstanding payments</div>
      ) : (
        <div>
          {payments &&
            orderBy(payments, ['created_at'], ['desc']).map((payment) => (
              <div key={payment.id} className="pb-1">
                <Expander title={<TitleRow payment={payment} />}>
                  {payment.entries.map((entry) => (
                    <Entry
                      entryId={entry.id}
                      key={entry.id}
                      mutate={mutate}
                      admin={true}
                      bookerActions={false}
                    />
                  ))}
                  {!paid && (
                    <ConfirmPayment payment={payment} mutate={mutate} />
                  )}
                  <div className="d-flex align-items-center pt-3">
                    <div className="me-2 fw-bold">Invoice</div>
                    <Invoice paymentId={payment.id} editable={false} />
                  </div>
                </Expander>
              </div>
            ))}
        </div>
      )}
    </>
  )
}

export default AdminPaymentsList
