import React, { useState } from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import addNewMatch from './add-new-match'

const PitchNewMatch = ({ baseUrl, pitches, allEntries, mutate }) => {
  const [homeTeam, setHomeTeam] = useState(null)
  const [awayTeam, setAwayTeam] = useState(null)
  const [pitch, setPitch] = useState(null)

  const clearSelection = () => {
    setHomeTeam(null)
    setAwayTeam(null)
    setPitch(null)
  }

  const { data: availableAwayTeams } = useSWR(
    homeTeam && homeTeam != -1
      ? `${baseUrl}/entries/${homeTeam}/to-play`
      : null,
    Fetcher
  )
  const { data: availableHomeTeams } = useSWR(
    awayTeam && awayTeam != -1
      ? `${baseUrl}/entries/${awayTeam}/to-play`
      : null,
    Fetcher
  )

  const homeTeams = availableHomeTeams || allEntries || []
  const awayTeams = availableAwayTeams || allEntries || []

  const canAdd = homeTeam && awayTeam && pitch

  const addMatch = () => {
    addNewMatch({
      baseUrl: baseUrl,
      homeTeamId: homeTeam,
      awayTeamId: awayTeam,
      pitchId: pitch.id,
      onSuccess: () => {
        mutate()
        clearSelection()
      },
    })
  }

  return (
    <div className="py-3 input-group">
      <select
        value={homeTeam || '-1'}
        onChange={(e) => setHomeTeam(e.target.value)}
        className="form-select"
      >
        <option value="-1">Select home team...</option>
        {homeTeams.map((team, key) => (
          <option id={`home_team_${team.id}`} value={team.id} key={key}>
            {team.teamName}
          </option>
        ))}
      </select>
      <span className="input-group-text">vs</span>
      <select
        value={awayTeam || '-1'}
        className="form-select"
        onChange={(e) => setAwayTeam(e.target.value)}
      >
        <option value="-1">Select away team...</option>
        {awayTeams.map((awayTeam, key) => (
          <option id={`away_team_${awayTeam.id}`} value={awayTeam.id} key={key}>
            {awayTeam.teamName}
          </option>
        ))}
      </select>
      <span className="input-group-text">on</span>
      <select
        value={pitch || '-1'}
        className="form-select"
        onChange={(e) => setPitch(e.target.value)}
      >
        <option value="-1">Select pitch...</option>
        {pitches &&
          pitches.map((pitch, key) => (
            <option id={`pitch_${pitch.id}`} value={pitch.id} key={key}>
              {pitch.name}
            </option>
          ))}
      </select>
      <button
        className={`btn btn-outline-secondary ${!canAdd && 'disabled'}`}
        type="button"
        onClick={addMatch}
      >
        Add fixture...
      </button>
    </div>
  )
}

export default PitchNewMatch
