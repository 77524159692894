import React from 'react'
import useSWR from 'swr'
import Currency from 'Lib/currency'
import Fetcher from 'Lib/fetcher'
import {
  faCaretCircleRight,
  faCircleNotch,
  faTrashAlt,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Entry from 'Lib/entry'
import pluralize from 'pluralize'
import { format, parseISO } from 'date-fns'

const TournamentsList = () => {
  const {
    data: tournaments,
    error: error,
    mutate,
  } = useSWR('/tournaments', Fetcher)

  const tournamentBasketValue =
    tournaments &&
    tournaments.reduce((total, tournament) => {
      if (tournament.entries && tournament.entries.length > 0) {
        tournament.entries.forEach((entry) => {
          if (!entry.entered) {
            total += parseFloat(entry.fee)
          }
        })
      }

      return total
    }, 0)

  const numTournaments =
    tournaments &&
    tournaments.reduce((total, tournament) => {
      if (tournament.entries && tournament.entries.length > 0) {
        total += tournament.entries.filter((e) => !e.entered).length
      }

      return total
    }, 0)

  const orderedTournaments =
    tournaments &&
    tournaments.sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })

  return (
    <div>
      {!tournaments && !error && (
        <div className="d-flex align-items-center w-100 justify-content-center">
          <FontAwesomeIcon icon={faCircleNotch} spin className="me-2" />
          loading tournaments...
        </div>
      )}
      {tournaments && (
        <>
          {tournamentBasketValue > 0 && (
            <a className="btn btn-info w-100 mb-3" href="/checkout">
              Confirm <span className="d-none d-sm-inline">entries for</span>{' '}
              {pluralize('squad', numTournaments, true)} (
              <Currency amount={tournamentBasketValue} />)
              <FontAwesomeIcon icon={faCaretCircleRight} className="ms-2" />
            </a>
          )}
          {tournaments.length === 0 && (
            <div className="alert alert-info">
              There are no available tournaments
            </div>
          )}
          <div className="list-group">
            {orderedTournaments.map((tournament, key) => (
              <div className="list-group-item d-flex flex-column" key={key}>
                <div className="d-flex flex-column flex-sm-row align-items-center">
                  <div className="d-flex flex-column nowrap me-0 me-sm-2 mb-3 mb-sm-0">
                    <div className="d-flex flex-row align-items-center">
                      <div className="fw-bold">{tournament.name}</div>
                      <div
                        className="ms-2 text-muted"
                        style={{ fontSize: '0.9rem' }}
                      >
                        [Squad size: {tournament.squad_size}]
                      </div>
                    </div>
                    <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                      {format(parseISO(tournament.starts_at), 'PPPP')}
                    </div>
                  </div>

                  {tournament.entries.filter((t) => t.entered).length > 0 && (
                    <div
                      className="ms-3 badge bg-success text-light mb-3 mb-sm-0"
                      style={{ cursor: 'pointer' }}
                      data-bs-toggle="collapse"
                      data-bs-target={`#entriesFor${tournament.id}`}
                    >
                      {tournament.entries.filter((t) => t.entered).length}{' '}
                      entries
                    </div>
                  )}
                  {tournament.entries.filter((t) => !t.entered).length > 0 && (
                    <div
                      className="ms-3 badge bg-info text-light mb-3 mb-sm-0"
                      style={{ cursor: 'pointer' }}
                      data-bs-toggle="collapse"
                      data-bs-target={`#entriesFor${tournament.id}`}
                    >
                      {tournament.entries.filter((t) => !t.entered).length}{' '}
                      pending
                    </div>
                  )}
                  <a
                    className="ms-0 ms-sm-auto btn btn-sm btn-dark text-light mb-3 mb-sm-0"
                    href={`/tournaments/${tournament.slug}/enter`}
                  >
                    Add Squad (
                    <Currency
                      amount={tournament.entry_fee}
                      className="fw-bold"
                    />
                    )
                  </a>
                </div>
                <div
                  className="collapse my-3"
                  id={`entriesFor${tournament.id}`}
                >
                  {tournament.entries.length > 0 && (
                    <div className="d-flex flex-column">
                      <div className="list-group">
                        <div className="list-group-item list-group-item-success">
                          Current entries
                        </div>
                        {tournament.entries.map((entry, key) => (
                          <Entry entry={entry} key={key} mutate={mutate} />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default TournamentsList
