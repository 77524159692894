import React from 'react'

const Currency = ({amount, className=''}) => {
  const formattedCurrency = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(amount)
  return (
    <span className={className}>
      {formattedCurrency}
    </span>
  )
}

export default Currency
