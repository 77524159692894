import React from 'react'
import useSWR from 'swr'
import Fetcher from 'Lib/fetcher'
import Loading from 'Lib/loading'
import orderBy from 'lodash.orderby'
import Confirm from 'Lib/confirm'
import Fetch from 'Lib/fetch'
import TournamentTeam from './team'

const TeamCheckIn = ({ tournament, mutateTournament }) => {
  const tournamentId = tournament.id
  const { data: entries, mutate } = useSWR(
    `/tournaments/${tournamentId}/entries`,
    Fetcher
  )

  const toBeCheckedInTeams =
    entries &&
    orderBy(
      entries.filter((e) => e.entered && !e.checkedIn),
      ['teamName']
    )

  const checkIn = (team) => {
    Confirm({
      title: 'Are you sure',
      message: `Are you sure you want to confirm ${team.teamName} has arrived by checking them in?`,
      confirmText: `Check in now`,
      onConfirm: () => {
        Fetch(`/tournaments/${tournamentId}/entries/${team.id}/check-in`, {
          method: 'PATCH',
        }).then((response) => {
          if (response.ok) {
            if (mutate) {
              mutate()
              mutateTournament()
            }
          } else {
            alert('An error occured - could not check in the team')
          }
        })
      },
    })
  }

  if (!entries) {
    return <Loading />
  } else {
    return (
      <div className="">
        <div className="list-group">
          {toBeCheckedInTeams.length > 0 && (
            <>
              <div className="list-group-item list-group-item-warning">
                Awaiting check in
              </div>
              {toBeCheckedInTeams.map((team, key) => (
                <TournamentTeam
                  team={team}
                  key={key}
                  checkIn={checkIn}
                  tournamentId={tournamentId}
                />
              ))}
            </>
          )}
          {toBeCheckedInTeams.length === 0 && (
            <div className="alert alert-success my-3">
              Good news, all teams have checked in!
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default TeamCheckIn
