import React, { useEffect, useState, Children } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretRight,
  faCaretDown,
  faCircleNotch,
} from '@fortawesome/pro-duotone-svg-icons'

const Expander = ({
  title,
  titleStyle = 'light',
  children,
  hasBody = true,
  open = false,
  loading = false,
  expandCallback,
  uniqueId,
  className = '',
  headerCustomStyle = {},
}) => {
  const id = `expand_${uniqueId || Math.floor(Math.random() * 200000)}`
  const [visible, setVisible] = useState(open)
  const [showing, setShowing] = useState(id)

  const toggleExpander = () => {
    if (!visible && expandCallback) {
      expandCallback()
    }
    setVisible(!visible)
  }

  useEffect(() => {
    if (showing !== id && uniqueId) {
      setVisible(false)
      setShowing(id)
    }
  }, [id])

  return (
    <div className={`card w-100 ${className}`}>
      <div className={`card-header ${titleStyle}`} style={headerCustomStyle}>
        <div className="d-flex align-items-center">
          <span
            className={`btn me-2 ${visible ? '' : 'collapsed'} p-0 ${
              !children && 'disabled'
            }`}
            data-bs-toggle="collapse"
            data-bs-target={`#${id}`}
            onClick={toggleExpander}
          >
            <FontAwesomeIcon icon={visible ? faCaretDown : faCaretRight} />
          </span>
          <div className="ms-2 fw-bold w-100">{title}</div>
          {loading && (
            <FontAwesomeIcon icon={faCircleNotch} className="ms-2" spin />
          )}
        </div>
      </div>
      <div
        className={`${hasBody && 'card-body'} collapse ${
          visible ? 'show' : ''
        }`}
        id={id}
      >
        {children}
      </div>
    </div>
  )
}

export default Expander
