import React from 'react'
import { CookiesProvider } from 'react-cookie'
import FixturesContainer from './container'

const Fixtures = () => {
  return (
    <CookiesProvider>
      <FixturesContainer />
    </CookiesProvider>
  )
}

export default Fixtures
