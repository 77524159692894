import { faTrashAlt, faHardHat } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Confirm from 'Lib/confirm'
import Fetch from 'Lib/fetch'

const AdminsDetail = ({ tournament, admin, mutateTournament }) => {
  const deleteAdmin = () => {
    Confirm({
      title: `Do you want to remove ${admin.name}`,
      message: `Are you sure you want to remove ${admin.name} as an admin of this tournament?`,
      onConfirm: () => {
        Fetch(`/tournaments/${tournament.id}/tournament_admins/${admin.id}`, {
          method: 'DELETE',
        }).then((response) => {
          if (response.ok) {
            if (mutateTournament) mutateTournament()
          } else {
            response
              .json()
              .then((body) => alert(`An error occurred: ${body.errors}`))
          }
        })
      },
      icon: faHardHat,
    })
  }
  return (
    <div
      className="d-flex align-items-center px-3 py-2 border rounded mb-2"
      key={admin.id}
    >
      <span>{admin.name ? `${admin.name} (${admin.email})` : admin.email}</span>
      <FontAwesomeIcon
        icon={faTrashAlt}
        className="ms-auto"
        role="button"
        onClick={deleteAdmin}
      />
    </div>
  )
}

export default AdminsDetail
